.Review-container-fluid {
  position: relative;
  width: 80vw;
}

.pagination .page-item .page-link.Review-setborder-prev.set-border-prev {
  border-right: 1px solid #dee2e6;
}
.pagination .page-item .page-link.Review-setborder-next.set-border-next {
  border-left: 1px solid #dee2e6;
}
.pagination .page-item .page-link {
  text-align: center;
}

.pagination .page-item .page-link.Review-setborder-next .glyphicon {
  top: 2px;
}

.pagination
  .page-item
  .page-link.Review-setborder-next
  .glyphicon.glyphicon-chevron-right,
.pagination
  .page-item
  .page-link.Review-setborder-next
  .glyphicon.glyphicon-chevron-left {
  right: 2px;
}

.wrapper-img {
  height: 250px;
  overflow: hidden;
}

.pagination .page-item .page-link.set-border1.active {
  background: #01adef;
  border-color: #01adef;
  color: #fff;
}

.review-card-container {
  background-color: rgb(255, 255, 255);
  color: #055cab;
  min-height: 480px;
}

.review-card-container .content {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 160px;
}

.review-card-container .content .review-card-header {
  margin-bottom: 12px !important;
  width: 75%;
  margin: 0 auto;
  padding-top: 20px;
}

.review-card-container img {
  height: 300px;
  width: 100%;
  object-fit: cover;
}

.review-card-container .review-card-button {
  background-color: #01adef;
  color: #ffffff;
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 600;
  outline: none;
  border: 1px solid transparent;

  transition: all 300ms;
  width: fit-content;
}

.review-card-container .review-card-button:hover {
  color: #0193cd;
  border-color: #0193cd;
  background-color: #ffffff;
}

@media (max-width: 767px) {
  .Review-container-fluid {
    width: 90vw !important;
    padding-right: 0;
    padding-left: 0;
  }
}
