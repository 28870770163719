.ConfirmContentA-settxtcen-default {
    text-align: center;
    margin: 55px 0 70px;
}
.ConfirmContentA-conw-logom {
    width: 10%;
}
.ConfirmContentA-buildbtn-danger {
    border-radius: 5px;
    width: 15%;
    padding: 15px 0;
}
.ConfirmContentA-confont-infoa {
    color: #818285;
    font-size: 15px;
    margin-bottom: 25px;
}
.ConfirmContentA-confont-infob {
    font-size: 15px;
    color: #fdb2ab;
}
.ConfirmContentA-confont-headinfo {
    font-size: 28px;
    color: #01adef;
    margin-bottom: 15px;
}
.ConfirmContentA-setmarb-logom,
.ConfirmContentA-setmarb-btn {
    margin-bottom: 20px;
}

@media (max-width: 992px) {
    .ConfirmContentA-conw-logom {
        width: 15%;
    }
    .ConfirmContentA-buildbtn-danger {
        width: 25%;
    }
}

@media (max-width: 768px) {
    .ConfirmContentA-conw-logom {
        width: 150px;
    }
    .ConfirmContentA-buildbtn-danger {
        width: 55%;
        font-size: 18px;
    }
}

@media (max-width: 650px) {
    .ConfirmContentA-buildbtn-danger {
        width: 70%;
    }
    .ConfirmContentA-confont-headinfo {
        font-size: 24px;
    }
}

@media (max-width: 550px) {
    .ConfirmContentA-buildbtn-danger {
        width: 80%;
    }
    .ConfirmContentA-confont-infoa {
        font-size: 15px;
    }
    .ConfirmContentA-confont-headinfo {
        font-size: 20px;
    }
    .ConfirmContentA-confont-infob {
        font-size: 14px;
    }
}

@media (max-width: 359px) {
    .ConfirmContentA-buildbtn-danger {
        width: 100%;
    }
    .ConfirmContentA-confont-headinfo {
        font-size: 17px;
    }
}
@media (max-width: 342px) {
    .ConfirmContentA-confont-infoa {
        font-size: 13px;
    }
    .ConfirmContentA-confont-infob {
        font-size: 12px;
    }
}
