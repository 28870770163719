.Enterprise-btn-readmore {
    padding: 10px 30px;
    color: #fff;
    font-size: 15px;
    background: #01adef;
    border-radius: 5px;
    border: 1px solid #01adef;
    margin-top: 30px;
}
.EnterpriseContentA-setbg-opacity {
    background: #055cab;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.5;
}

.EnterpriseContentB-span-blue {
    color: #01adef;
}
.EnterpriseContentB-span-gray {
    color: #818285;
}
.EnterpriseContentB-area-content {
    padding: 60px 80px;
}
.EnterpriseContentB-con-phonelogo {
    margin-top: 35px;
}
.EnterpriseContentB-setmarl-logonum {
    margin-left: 15px;
}
.row .col-md-1.EnterpriseContentB-setmart-logonum {
    margin-top: 22px;
}
.EnterpriseContentB-head-content {
    margin-top: 30px;
    margin-bottom: 15px;
    font-size: 17px;
}
.EnterpriseContentB-setmart-space {
    margin-top: 30px;
}
.EnterpriseContentA-contain-default {
    text-align: center;
    margin-top: 80px;
    position: relative;
}

@media (max-width: 992px) {
    .EnterpriseContentB-con-phonelogo,
    .EnterpriseContentB-repad-mobile,
    .EnterpriseContentB-setmart-logonum,
    .EnterpriseContentB-setmarl-logonum {
        padding-left: 0;
    }
    .row .col-md-5.EnterpriseContentB-setmarl-logonum {
        margin-left: 0;
        margin-top: 50px;
    }
    .row .col-md-1.EnterpriseContentB-setmart-logonum {
        margin-top: 32px;
    }
    .row .col-md-1.EnterpriseContentB-setmart-logonum {
        margin-left: -20px;
    }
    .EnterpriseContentB-con-phonelogo,
    .EnterpriseContentB-setmart-logonum {
        float: left;
    }
    .row .col-md-1.EnterpriseContentB-con-phonelogo {
        margin-top: 10px;
    }
    .EnterpriseContentB-repad-mobile {
        margin-left: 86px;
    }
    .EnterpriseContentB-span-blue-mo {
        font-size: 18px;
    }
    .EnterpriseContentB-setmart-space {
        margin-top: 45px;
    }
}

@media (max-width: 768px) {
    .EnterpriseContentB-area-content {
        padding: 60px 30px;
    }
    .EnterpriseContentB-settxtcen-blue {
        text-align: center;
    }
}

@media (max-width: 550px) {
    .row .col-md-1.EnterpriseContentB-setmart-logonum {
        margin-top: 45px;
    }
    .row .col-xs-12.EnterpriseContentB-area-content {
        margin-top: 0;
    }
    .EnterpriseContentB-span-blue-mo {
        font-size: 16px;
    }
    .EnterpriseContentB-content-inside {
        font-size: 13px;
    }
    .EnterpriseContentB-head-content {
        font-size: 15px;
    }
    .EnterpriseContentB-repad-mobile {
        font-size: 13px;
    }
    .EnterpriseContentB-setmarl-logonum {
        font-size: 13px;
    }
    .EnterpriseContentA-contain-default {
        margin-top: 35px;
    }
}

@media (max-width: 380px) {
    .EnterpriseContentB-con-phonelogo {
        margin-top: 35px;
    }
}
