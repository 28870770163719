.Journey-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.Journey-item-container .img-container {
  overflow: hidden;
  width: 40%;
  border-radius: 8px;
}

.Journey-item-container .img-container img {
  height: 220px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.Journey-item-container .content-container {
  padding: 20px;
  width: 60%;
}

.Journey-item-container .content-container .content-title {
  color: #0193cd;
  font-weight: bold;
  cursor: pointer;
}

.Journey-item-container .content-container .content-title:hover {
  color: #0193cd;
  text-decoration: underline;
}

.Journey-item-container .img-container img:hover {
  border-radius: 8px;
  opacity: 0.6;
}

@media (max-width: 768px) {
  .Journey-item-container {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .Journey-item-container .img-container {
    width: 100%;
  }

  .Journey-item-container .img-container img {
    height: 200px;
    width: 100%;
    object-fit: cover;
  }

  .Journey-item-container .content-container {
    padding: 10px;
    width: 100%;
  }
}
