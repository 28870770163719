.Book3ContentA-headmodal-blue {
  color: #01adef;
}

.Book3ContentA-contentmodal-gray {
  color: #818285;
}

.Book3ContentA-headmodal-red {
  color: #ec1e25;
}

.Book3ContentA-repad-infos .checkbox .cr {
  border-radius: 50%;
}

.Book3ContentA-confont-same {
  color: #a8a9ac;
}

.Book3ContentA-chk-condot.Book3ContentA-chk-condot-a {
  top: -3.4px;
  left: 4.8px;
}

.Book3ContentA-chk-condot-r.Book3ContentA-chk-condot-rab {
  top: -3.5px;
}

.Book3ContentA-chk-condot.Book3ContentA-chk-condot-a.Book3ContentA-chk-condot-ab {
  top: -3.6px;
  left: 4.1px;
}

.Book3ContentA-span-chkb {
  margin-left: 10px;
}

.Book3ContentA-chk-condot-r.Book3ContentA-chk-condot-ab {
  top: -3.4px;
}

.Book3ContentA-chk-condot {
  font-size: 10px;
  position: relative;
  top: -3px;
  left: 4px;
  color: #055cab;
}

.Book3ContentA-repad-infos .checkbox .cr {
  margin-right: 0em;
}

.Filter-confont-same {
  font-size: 11px;
}

.Book3ContentA-img-marr {
  margin-right: 0;
}

.Book3ContentA-set-select {
  margin-left: -20px;
}

.Book3ContentA-concheck {
  margin-top: 10px;
}

.Book3ContentA-flo-mar5 {
  margin-top: 15px;
}

.modal-dialog-centered {
  top: 23vh;
}

#myInput {
  border: none;
  background: #fff;
}

.Book3ContentA-headmodal-blue,
.Book3ContentA-contentmodal-gray,
.Book3ContentA-headmodal-red {
  font-size: 12px;
}

.modal-backdrop {
  /*position: unset;*/
}

.modal-header {
  background: #055cab;
}

.modal-title {
  color: #fff;
}

.Book3ContentA-conbtn-closemodal {
  opacity: 1;
  font-size: 28px;
  color: #fff;
}

.Book3ContentA-conbtn-closemodal:hover {
  cursor: pointer;
}

#myInput:hover {
  cursor: pointer;
}

.Book3ContentA-rebg-modalh-fail {
  background: #fff;
  border-bottom: #fff;
  padding-top: 10px;
}

.Book3ContentA-conbtn-closemodal-fail {
  opacity: 1;
  font-size: 40px;
  color: #000;
}

.Book3ContentA-conbtn-closemodal-fail:hover {
  cursor: pointer;
}

.Book3ContentA-rebuild-btn-modal-f-fail {
  background: #fff;
  color: #01adef;
  padding: 7px 20px;
  border-radius: 16px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.Book3ContentA-rebuild-btn-modal-f-fail:hover {
  color: #01adef;
  border-radius: 16px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.Book3ContentA-setpad-modal-f-fail {
  border-top: unset;
}

.Book3ContentA-setpad-modal-b-fail {
  margin-top: 15px;
  padding-left: 30px;
}

.Book3ContentA-conspan-modal-b-fail {
  font-size: 12px;
  font-weight: 600;
}

.Book3ContentA-modal-body {
  max-height: calc(95vh - 100px);
  overflow-y: auto;
}

.Book3ContentA-confont-choose {
  font-size: 12px;
}

.radio .Book3ContentA-con-radio::before {
  top: 7px;
}

.radio .Book3ContentA-con-radio::after {
  top: 12px;
}

.radio .Book3ContentA-con-radio::after {
  top: 9px;
}

.radio .Book3ContentA-con-radio::before {
  top: 4px;
}

.close {
  font-size: 52px;
  position: absolute;
  top: 0px;
  right: 10px;
  margin-top: 0;
}

@media (min-width: 991px) {
  .Book3ContentA-chk-mobile {
    display: none !important;
  }
}

@media (max-width: 600px) {
  .BookHeaderStatus-hidemo {
    display: none;
  }

  .BookHeaderStatus-reset-col {
    width: 100%;
    padding: 0;
  }
}

@media (max-width: 412px) {
  .Book3ContentA-repad-infos .checkbox .cr {
    margin-right: 5px;
  }
}

@media (max-width: 355px) {
  .Book3ContentA-confont-same {
    font-size: 10px;
  }

  .Book3ContentA-pad-info {
    margin-left: 0;
    padding: 0 15px 30px 15px;
  }
}

@media (max-width: 350px) {
  .Book3ContentA-confont-choose {
    font-size: 10px;
  }

  .Book3ContentA-setbgW .Book3ContentA-pad-info .Book3ContentA-con-radio {
    padding-left: 0;
  }
}

.info-icon {
  width: 25px;
  height: 25px;
  display: inline-block;
  background: #055cab;
  border: 2px solid #FFF;
  text-align: center;
  line-height: 25px;
  border-radius: 50%;
  margin-top: 3px;
  font-size: 20px;
}


@media (min-width: 768px) {
  .modal-dialog {
    width: 550px;
  }
}