.container-title .ProfileContentA-hr-promo {
  background: #01adef;
  height: 7px;
  display: inline-block;
  margin-left: 0px;
}

.container-title .ProfileContentA-conhr-head {
  min-width: 60px;
}

.container-title .ProfileContentA-confont-header {
  font-size: 35px;
  color: #01adef;
}

.ProfileContentA-contain-default {
  text-align: center;
  margin-top: 40px;
}

.ProfileContentA-setmarlr-de {
  margin: 70px 100px 10px 150px;
}

.ProfileContentA-relink-btn {
  border: none;
  background: #fff;
}

.ProfileContentA-conh-input {
  height: 44.5px;
  color: #2699fb;
}

.ProfileContentA-conmarb-input {
  overflow: hidden;
  margin-bottom: 20px;
}

.ProfileContentA-conspan-front {
  background: #01adef;
  color: #fff;
  font-size: 12px;
  padding: 0 20px;
}

.ProfileContentA-conspan-front {
  background: #01adef;
  color: #fff;
  font-size: 12px;
  padding: 0 10px;
  border-color: #01adef;
}

.bd-red span,
.bd-red input,
.bd-red input,
.bd-red select {
  border: 1px solid #ee4231;
}

.ProfileContentA-setmarlr-de .row .ProfileContentA-remart-colform {
  margin-top: 0;
}

.ProfileContentA-buildbtn-danger {
  border: 1px solid #ee4231;
  border-radius: 5px;
  width: 20%;
  padding: 10px 0;
}

.ProfileContentA-buildbtn-primary {
  border: 1px solid #337ab7;
  border-radius: 5px;
  width: 20%;
  padding: 10px 0;
}

.ProfileContentA-conspan-remark {
  font-size: 10px;
  color: #ee4231;
}

.ProfileContentA-setmarlr-de .row .ProfileContentA-remart-colform.ProfileContentA-setmart-fremark {
  margin-top: 15px;
}

.ProfileContentA-conspan-forgot {
  font-size: 10px;
  color: #01adef;
}

.ProfileContentA-getdri-upload {
  position: relative;
  top: -44px;
  width: 150px;
  left: -107px;
  height: 45px;
}

.ProfileContentA-getiden-upload {
  position: relative;
  top: -44px;
  width: 170px;
  left: -127px;
  height: 45px;
}

/* Con span blue */
.ProfileContentA-conspan-first {
  padding-right: 35px;
}

.ProfileContentA-conspan-last {
  padding-right: 35px;
}

.ProfileContentA-conspan-mid {
  padding-right: 22px;
}

.ProfileContentA-conspan-mail {
  padding-right: 67px;
}

.ProfileContentA-conspan-add {
  padding-right: 48px;
}

.ProfileContentA-conspan-post {
  padding-right: 42px;
}

.ProfileContentA-conspan-phone {
  padding-right: 59px;
}

.ProfileContentA-conspan-nation {
  padding-right: 35px;
}

.ProfileContentA-conspan-country {
  padding-right: 49px;
}

.ProfileContentA-conspan-date {
  padding-right: 21px;
}

.ProfileContentA-conspan-face {
  padding: 0 46px;
  background: #055cab;
}

.ProfileContentA-conspan-line {
  padding: 0 34px;
  background: #3ace01;
  border: 1px solid #3ace01;
}

.ProfileContentA-conspan-current {
  padding-right: 52px;
}

.ProfileContentA-conspan-new {
  padding-right: 70px;
}

.ProfileContentA-conspan-renew {
  padding-right: 25px;
}

/* Con span blue */

.ProfileContentA-repad-monthform,
.ProfileContentA-repad-yearform {
  padding: 0 5px;
}
.ProfileContentA-setmarb-fform i {
  vertical-align: middle;
}
.ProfileContentA-rewfull-iden {
  padding-left: 7px;
}

.ProfileContentA-setborder-logoup {
  border-left: 1px;
}

.ProfileContentA-hovercursor:hover {
  cursor: pointer;
}

.ProfileContentA-buildlink-head {
  color: #fff;
  padding: 13px 30px;
}

.ProfileContentA-setbgcolor-ac {
  background: #01adef;
}

.ProfileContentA-setbgcolor-noac {
  background: #d2d3d5;
}

.ProfileContentA-setmarb-fform {
  margin-bottom: 20px;
}

.ProfileContentA-setlogo-prolink {
  width: 30px;
  margin-right: 30px;
}

.ProfileContentA-setlogo-booklink {
  width: 30px;
  margin-right: 30px;
}

.ProfileContentA-confont-link {
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 600;
  color: #fff;
}

.ProfileContentA-setmarb-newingroup {
  display: none;
}

.ProfileContentA-getdri-upload,
.ProfileContentA-getiden-upload {
  opacity: 0;
}

.ProfileContentA-getdri-upload:hover,
.ProfileContentA-getiden-upload:hover,
.ProfileContentA-conw-spanup {
  cursor: pointer;
}

.ProfileContentA-setmarb-fform div[class*="col-"] {
  margin-top: 10px;
}


.ProfileContent-button {
  font-size: 14px;
  /* font-weight: 600; */
  letter-spacing: 1px;
  padding: 10px 0;
  width: 160px;
  /* border: 1px solid #ee4231; */
  border-radius: 5px;
}

.logout-icon {
  margin: 0 10px 0 5px;
  font-size: 30px;
}

@media (min-width: 993px) {
  .ProfileContentA-repadr-prolink {
    padding-right: 0;
  }

  .ProfileContentA-repadl-booklink {
    padding-left: 0;
  }


  .ProfileContentA-repadr-prolink {
    position: absolute;
    margin-left: -200px;
    top: 122px;
    width: 200px;
  }

  .ProfileContentA-setlogo-prolink {
    width: 28px;
    margin-right: 9px;
  }

  .ProfileContentA-buildlink-head {
    padding: 20px 10px;
    height: 65px;
    position: relative;
  }

  .ProfileContentA-setlogo-booklink {
    width: 28px;
    margin-right: 9px;
  }

  .ProfileContentA-repadl-booklink {
    position: absolute;
    margin-left: -186px;
    top: 200px;
    width: 200px;
  }

  .ProfileContentA-repadl-logout {
    position: absolute;
    margin-left: -186px;
    top: 276px;
    width: 200px;
    padding-left: 0;
  }

  .ProfileContentA-confont-link {
    font-size: 14px;
    position: absolute;
    top: 24px;
    left: 55px;
  }
}

@media (max-width: 1200px) {
  .ProfileContentA-noneipad {
    display: none;
  }

  .ProfileContentA-rew-datemobile {
    padding-right: 0;
    width: 23%;
  }

  .ProfileContentA-repad-monthform {
    width: 9%;
    padding-right: 0;
  }

  .ProfileContentA-repad-yearform {
    width: 11.5%;
  }

  .ProfileContentA-refont-dobmo {
    /* padding: 0; */
    text-align: center;
  }

  .ProfileContentA-conspan-front {
    font-size: 10px;
    padding: 0 5px;
  }

  .ProfileContentA-refont-dobmo {
    font-size: 10px;
  }

  /* Con span blue */
  .ProfileContentA-conspan-first {
    padding-right: 16px;
  }

  .ProfileContentA-conspan-last {
    padding-right: 26px;
  }

  .ProfileContentA-conspan-mid {
    padding-right: 4px;
  }

  .ProfileContentA-conspan-mail {
    padding-right: 42px;
  }

  .ProfileContentA-conspan-add {
    padding-right: 27px;
  }

  .ProfileContentA-conspan-post {
    padding-right: 22px;
  }

  .ProfileContentA-conspan-phone {
    padding-right: 46px;
  }

  .ProfileContentA-conspan-nation {
    padding-right: 16px;
  }

  .ProfileContentA-conspan-date {
    padding-right: 5px;
  }

  .ProfileContentA-conspan-face {
    padding: 0px 29px;
  }

  .ProfileContentA-conspan-line {
    padding: 0 22px;
  }

  .ProfileContentA-conspan-current {
    padding-right: 26px;
  }

  .ProfileContentA-conspan-new {
    padding-right: 42px;
  }

  .ProfileContentA-conspan-renew {
    padding-right: 4px;
  }

  /* Con span blue */
  .ProfileContentA-rewfull-up {
    padding-left: 16px;
    margin-left: 0px;
  }

  .ProfileContentA-rewfull-iden {
    padding-left: 22.5px;
  }

  .ProfileContentA-buildbtn-danger {
    width: 30%;
  }

  .ProfileContentA-getdri-upload {
    width: 130px;
    left: -85px;
    z-index: 9999;
  }

  .ProfileContentA-getiden-upload {
    width: 145px;
    left: -100px;
    z-index: 9999;
  }
}

@media (max-width: 992px) {
  .ProfileContentA-setbgcolor-ac {
    line-height: 12px;
  }
  .logout-icon {
    margin-right: 35px;
  }

  .memberno_txt {
    margin-left: 60px;
  }

  .ProfileContentA-setmarlr-de {
    margin: 40px 60px 70px;
  }

  .ProfileContentA-rewfull-up {
    padding-left: 43px;
  }

  .ProfileContentA-rewfull-iden {
    padding-left: 27px;
  }

  .ProfileContentA-repad-monthform {
    width: 9.444445%;
    margin-left: 5px;
  }

  .ProfileContentA-repad-yearform {
    width: 11.444445%;
    margin-left: 5px;
  }

  .ProfileContentA-conspan-current {
    padding: 0 15px;
  }

  .ProfileContentA-conspan-new {
    padding: 0 23px;
  }
}

@media (max-width: 768px) {
  .ProfileContent-button {
    display: block;
    width: 100%;
    margin-left: 0 !important;
    margin-bottom: 15px;
  }

  .ProfileContentA-noneipad {
    display: block;
    width: 35%;
    float: unset;
  }

  .ProfileContentA-conspan-front {
    font-size: 12px;
    padding: 0 10px;
  }

  /* Con span blue */
  .ProfileContentA-conspan-first {
    padding-right: 35px;
  }

  .ProfileContentA-conspan-last {
    padding-right: 36px;
  }

  .ProfileContentA-conspan-mid {
    padding-right: 22px;
  }

  .ProfileContentA-conspan-mail {
    padding-right: 67px;
  }

  .ProfileContentA-conspan-add {
    padding-right: 48px;
  }

  .ProfileContentA-conspan-post {
    padding-right: 42px;
  }

  .ProfileContentA-conspan-phone {
    padding-right: 59px;
  }

  .ProfileContentA-conspan-nation {
    padding-right: 35px;
  }

  .ProfileContentA-conspan-date {
    padding-right: 21px;
  }

  .ProfileContentA-conspan-face {
    padding: 0px 45px;
  }

  .ProfileContentA-conspan-line {
    padding: 0 34px;
  }

  .ProfileContentA-conspan-current {
    padding: 0 29px;
  }

  .ProfileContentA-conspan-new {
    padding: 0 38px;
  }

  .ProfileContentA-conspan-renew {
    padding: 0 16px;
  }

  /* Con span blue */
  .ProfileContentA-rew-datemobile {
    width: 200px;
  }

  .ProfileContentA-repad-monthform {
    width: 83px;
  }

  .ProfileContentA-repad-yearform {
    width: 83px;
  }

  .ProfileContentA-rewfull-up {
    padding-left: 15px;
    width: 30%;
    float: left;
  }

  .ProfileContentA-rewfull-iden {
    padding-left: 15px;
    width: 50%;
    float: left;
  }

  .ProfileContentA-setinblock-face {
    display: inline-block;
  }

  .ProfileContentA-conw-spanup {
    padding: 0 18px;
    width: auto;
    line-height: 40px;
  }

  .ProfileContentA-conw-spaniden {
    padding: 0px 21px;
  }

  .ProfileContentA-getdri-upload {
    width: 168px;
    left: -125px;
  }

  .ProfileContentA-getiden-upload {
    width: 190px;
    left: -147px;
  }

  .ProfileContentA-buildbtn-danger {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    padding: 20px 0;
    width: 60%;
  }

  .ProfileContentA-setmart-fremark {
    text-align: center;
  }

  .ProfileContentA-setmarlr-de .row .ProfileContentA-remart-colform.ProfileContentA-setmart-fremark {
    margin-top: 40px;
  }

  /* .ProfileContentA-setmarlr-de .row .ProfileContentA-repadr-prolink,
  .ProfileContentA-setmarlr-de .row .ProfileContentA-repadl-booklink {
    margin-top: 0;
  } */
}

@media (max-width: 700px) {
  .ProfileContentA-setmarlr-de {
    margin: 70px 15px;
  }


  .ProfileContentA-conh-input {
    height: 45px;
  }
}

@media (max-width: 602px) {
  .ProfileContentA-buildbtn-danger {
    padding: 10px 0;
  }

  .ProfileContentA-conw-spanup {
    padding: 0 0px;
  }

  .ProfileContentA-rewfull-iden {
    padding-left: 0px;
  }

  .ProfileContentA-conw-spaniden {
    padding: 0px 10px;
  }

  .ProfileContentA-getdri-upload {
    width: 170px;
    left: -125px;
  }

  .ProfileContentA-getiden-upload {
    width: 209px;
    left: -170px;
  }

  .ProfileContentA-buildlink-head {
    padding: 20px;
    padding-left: 80px;
    padding-right: 0;
  }

  .ProfileContentA-setlogo-prolink {
    margin-right: 30px;
  }

  .ProfileContentA-setlogo-booklink {
    margin-right: 30px;
  }

  .ProfileContentA-rewfull-iden,
  .ProfileContentA-rewfull-up {
    width: 100%;
  }

  .ProfileContentA-setmarlr-de .row .ProfileContentA-remart-colform {
    padding-left: 15px;
  }

  .ProfileContentA-conw-spaniden {
    padding: 0px 33px;
  }

  .ProfileContentA-conw-spanup {
    padding: 0 20px;
  }
}

@media (max-width: 550px) {
  .logout-icon {
    margin-right: 16px;
  }

  .ProfileContentA-buildbtn-danger {
    width: 80%;
  }

  .memberno_txt {
    margin-left: 40px;
  }

  .ProfileContentA-setlogo-prolink {
    margin-right: 10px;
    width: 30px;
  }

  .ProfileContentA-setlogo-booklink {
    margin-right: 10px;
    width: 30px;
  }

  .ProfileContentA-setborder-logoup {
    border-left: 1px;
    border-bottom: 0.7px solid #fff;
    border: 0.1px solid #fff;
  }
}

@media (max-width: 450px) {
  .ProfileContentA-buildbtn-danger {
    width: 90%;
  }

  .ProfileContentA-confont-link {
    font-size: 13px;
  }

  .ProfileContentA-rew-datemobile {
    width: 165px;
  }

  .ProfileContentA-repad-monthform {
    width: 59px;
  }

  .ProfileContentA-repad-yearform {
    width: 67px;
  }
}

@media (max-width: 414px) {

  .ProfileContentA-rewfull-up,
  .ProfileContentA-rewfull-iden {
    display: none;
  }

  .ProfileContentA-setbg-confont {
    font-size: 12px;
  }

  .ProfileContentA-setpad-moiden {
    padding: 15px 25px;
  }

  .row .col-sm-3.ProfileContentA-setmarb-newingroup {
    margin-top: 20px;
  }

  .ProfileContentA-redisup,
  .ProfileContentA-redisiden {
    display: flex;
  }

  .ProfileContentA-conwper-ingroup {
    width: 50%;
  }

  .ProfileContentA-repad-logoup {
    padding: 0;
    border: 0px solid #055cab;
  }

  .ProfileContentA-setbg-confont {
    background: #01adef;
    color: #fff;
  }

  .ProfileContentA-setmarb-newingroup {
    margin-bottom: 20px;
  }

  .ProfileContentA-getdriV2-upload {
    position: absolute;
    top: 0px;
    width: 199px;
    left: 0px;
    opacity: 0;
    height: 45px;
  }

  .ProfileContentA-getidenV2-upload {
    position: absolute;
    top: 0px;
    width: 200px;
    left: 0px;
    opacity: 0;
    height: 45px;
  }

  .ProfileContentA-setmarb-newingroup {
    display: block;
  }
}

@media (max-width: 400px) {
  .ProfileContentA-confont-link {
    letter-spacing: 0px;
  }
}

@media (max-width: 366px) {
  /* .ProfileContentA-setlogo-prolink {
    display: block;
    margin-left: 53px;
  }

  .ProfileContentA-confont-linkpro {
    margin-left: 27px;
  }

  .ProfileContentA-setlogo-booklink {
    display: block;
    margin-left: 47px;
    height: 20px;
  } */

  .ProfileContentA-confont-linkbook {
    margin-left: 14px;
  }
}

@media (max-width: 360px) {
  .ProfileContentA-setmarlr-de .row .ProfileContentA-repad-monthform {
    padding-left: 0;
    width: 44px;
  }

  .ProfileContentA-setmarlr-de .row .ProfileContentA-repad-yearform {
    padding-left: 0;
    width: 60px;
  }
}

@media (max-width: 330px) {
  /* .ProfileContentA-setlogo-prolink {
      margin-left: 44px;
    }

    .ProfileContentA-confont-linkpro {
      margin-left: 16px;
    }

    .ProfileContentA-setlogo-booklink {
      margin-left: 39px;
    } */
}

.input-group .form-control.ProfileContentA-refont-dobmo {
  float: left;
  display: inline-block;
  width: 100px;
}