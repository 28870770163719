.RegisModal-setmar-signin {
    margin: 15px 0;
}
.row .col-xs-12.RegisModal-remart-logf {
    margin-top: 10px;
}

.row .col-xs-12.RegisModal-remart-logl {
    margin-top: 10px;
}

.registerHeadline {
    background: #055cab;
    
    
}
.registerHeadline h1 {
    color: #FFF;
    text-align: center;
    padding: 10px 0;
    font-size: 24px;
}

@media (max-width: 560px) {
    .Regis-reset-contianer {
        margin: 10px 15px;
    }
}

@media (max-width: 340px) {
    .Regis-reset-contianer {
        margin: 10px 0px;
    }
}

.bd-red {
    border-color: #d43f3a !important;
}
