body {
  background: rgb(235, 232, 232);
  font-family: "Prompt", sans-serif;
}

.content-area {
  z-index: unset;
}

.ProContentA-setbgheader {
  background-position: center;
}

.setbgheader-parallax {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.set-maxheight-txtrow {
  max-height: 44px;
  overflow: hidden;
  margin-top: 20px;
  min-height: 45px;
}

#preloader2 {
  height: 100px;
  position: relative;
}

#preloader2 #preloader-status {
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.wrap-form-filter
  .main-slider
  .form-search
  .has-icon
  .form-control.Book1ContentA-repadl-input,
.wrap-form-filter
  .form-search
  .has-icon
  .form-control.Book1ContentA-repadl-input {
  padding-left: 5px;
}

.Review-set-shadow .set-btn-slide > a:hover {
  background: #fff;
  color: #01adef;
  border: 1px solid #01adef;
}

.Review-set-shadow {
  box-shadow: 0 10px 18px 0 rgba(0, 0, 0, 0.2), 0 10px 15px 0 rgba(0, 0, 0, 0.2);
}

.react-datepicker {
  font-size: 1em !important;
}

.react-datepicker__header {
  padding-top: 0.8em !important;
}

.react-datepicker__month {
  margin: 0.4em 1em !important;
}

.react-datepicker__day-name,
.react-datepicker__day {
  width: 1.9em !important;
  line-height: 1.9em !important;
  margin: 0.166em !important;
}

.react-datepicker__current-month {
  font-size: 1em !important;
}

.react-datepicker__navigation {
  top: 1em !important;
  line-height: 1.7em !important;
  border: 0.45em solid transparent !important;
}

.react-datepicker__navigation--previous {
  border-right-color: #999 !important;
  left: 6px !important;
}

.react-datepicker__navigation--next {
  border-left-color: #999 !important;
  right: 6px !important;
}

.react-datepicker__portal .react-datepicker__current-month {
  font-size: 2rem !important;
  line-height: 1;
}

.react-datepicker__day-names {
  background: #9999;
}

.ProContentA-wrapper-img {
  overflow: hidden;
  box-shadow: 0 10px 18px 0 rgba(0, 0, 0, 0.2), 0 10px 15px 0 rgba(0, 0, 0, 0.2);
}

.Promotion-fluid {
  position: relative;
  width: 80vw;
}

.Top-Filter-fluid {
  position: relative;
  width: 80vw;
}

.Footer-fluid {
  position: relative;
  width: 80vw;
}

.promotion-card-container {
  background-color: rgb(255, 255, 255);
  color: #055cab;
  padding-bottom: 40px;
}

.promotion-card-container .content {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 140px;
}

.promotion-card-container .content .promotion-card-header {
  margin-bottom: 12px !important;
  width: 75%;
  margin: 0 auto;
  padding-top: 20px;

  min-height: 50px;
}

.promotion-card-container img {
  width: 100%;
  object-fit: cover;
}

.promotion-card-container .promotion-card-button {
  background-color: #01adef;
  color: #ffffff;
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 600;
  outline: none;
  border: 1px solid transparent;

  transition: all 300ms;
  width: fit-content;
}

.promotion-card-container .promotion-card-button:hover {
  color: #0193cd;
  border-color: #0193cd;
  background-color: #ffffff;
}

.set-remaxheight-txtrow {
  max-height: 60px;
  overflow: hidden;
  padding-top: 15px;
  min-height: 60px;
  margin-bottom: 20px;
}

.set-placehol::placeholder {
  color: #bce0fd !important;
}

.sticky-wrapper {
  z-index: 9999;
}

.HomeFilter-chk-procode {
  font-size: 27px;
}

.ReviewContentA-setbgheader {
  /* background: url("images/Review/bg-review-header@2x.png"); */
  background-position: center;
}

.ReviewContentA-setbg-opacity {
  background: #055cab;
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.5;
}

.main-slider .form-search .control-form-check-a {
  width: 100%;
  top: 30px;
  margin-right: 10px;
}

.container .btn-theme-transparent.Header-btnrehover:hover {
  background-color: #fff;
  color: #01adef;
}

.ReviewContentA-contain-default {
  position: relative;
}

.btn-red {
  background-color: #ec1e25 !important;
  border-color: #ec1e25 !important;
}

.btn-blue {
  background-color: #01adef !important;
  border-color: #01adef !important;
}

.font-blue {
  color: #01adef !important;
}

.main-slider .ver3 .form-search {
  float: left;
  margin-left: 0;
  width: 50%;
  padding-bottom: 24px;
}

.main-booking-form .form-search .form-title {
  background-color: #055cab;
}

.main-booking-form .form-search .form-title:after {
  display: none;
}

.wrap-form-filter .form-search .form-title {
  background-color: #055cab;
  margin-bottom: 0;
}

.wrap-form-filter .form-search .form-title:after {
  display: none;
}

.has-label .form-control-icon {
  top: 0;
}

.content-area .dark .container-fluid .copy-footer .logo-footer-th {
  width: 140px;
}

@media (max-width: 767px) {
  .Promotion-fluid {
    width: 90vw !important;
    padding-right: 0;
    padding-left: 0;
  }

  .Top-Filter-fluid {
    width: 90vw !important;
    padding-right: 0;
    padding-left: 0;
  }

  .Footer-fluid {
    width: 90vw !important;
    padding-right: 0;
    padding-left: 0;
  }
}

@media (max-width: 1200px) {
  .wrap-form-filter .has-label .form-control-icon {
    top: 28px;
  }
}

@media (max-width: 490px) {
  .wrap-form-filter .has-label .form-control-icon {
    top: 25px;
  }
}

.form-search select {
  -webkit-appearance: menulist;
}

.form-search select:focus {
  -webkit-appearance: menulist;
}

.form-search label {
  color: #01adef;
}

.form-search .form-control-icon {
  background-color: #01adef;
  color: #fff;
}

.form-search .form-control-btn {
  position: absolute;
  top: 27px;
  right: 0;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  width: 100px;
  height: 39px;
  line-height: 30px;
  font-size: 14px;
  background-color: #01adef;
  color: #fff;
  border: 1px solid transparent;
}

.form-search .form-control,
.form-search .form-control:focus {
  border-color: #bce0fd;
  color: #01adef;
}

.main-slider .form-search.light .form-title .fa,
.wrap-form-filter .form-search.light .form-title .fa {
  float: none;
  line-height: 18px;
  font-size: 24px;
  margin: 0 10px;
  color: #fff;
}

::placeholder {
  color: #bce0fd;
}

.image-hover {
  width: 100%;
  height: 100%;
  background: rgba(2, 58, 114, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.image-hover a {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.thumbnail.thumbnail-featured .btn-theme {
  background-color: transparent !important;
}

.logo {
  width: auto;
  height: auto;
  line-height: 80px;
  position: absolute;
  z-index: 999;
  top: 0;
  background-color: transparent !important;
}

.logo a {
  display: unset;
}

.is-sticky .logo {
  height: 50px;
  line-height: 40px;
}

.is-sticky .logo.control-phone-header {
  margin-left: 108px;
}

.dark .social-icons img {
  width: 100%;
}

.dark .copy-footer .ex-img {
  width: 20%;
}

.dark .row .copy-footer {
  margin-top: 50px;
}

.copy-footer .logo-footer-th {
  margin-bottom: 26px;
}

.menu-footer .row .col-md-5 > ul > li,
.menu-footer .row .col-md-6 > ul > li {
  margin-top: 10px;
}

.sf-menu.nav > li > a {
  text-transform: none;
  font-weight: 500;
}

.page-section.dark {
  background-color: #023a72 !important;
  color: #fff;
}

.navigation .sf-menu .social-icons img {
  width: 100%;
}

.social-icons a {
  width: 22px;
  height: 22px;
  line-height: 22px;
  border: none;
  margin-left: 15px;
}

.dark #contact-form .form-control,
.dark #contact-form .form-control:focus {
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid #fff;
}

.dark a {
  color: #fff !important;
}

.main-slider
  .form-search
  .form-title
  .row-inputs
  .control-form-group-refresh
  .control-btn-refresh {
  border-radius: 100px;
  height: 49px;
  width: 100%;
  font-size: 17px;
  background: #ea2b2b;
  text-transform: uppercase;
  font-weight: 600;
}

.wrapper .navigation .sf-menu .control-btn-login {
  border-radius: 16px;
  font-size: 15px;
  color: #fff;
  background: #01adef;
  border-color: #fff;
  padding: 10px 22px;
  top: 18px;
  margin-top: 18px;
  transition: 0.3s;
}

#loginmodal .modal-txtheadcen {
  background: #055cab;
}

.modal-header {
  background: #055cab;
}

.modal-header .modal-title {
  color: #fff;
}

#loginmodal .LoginModal-title {
  color: #fff;
}

.Login-relink-btn {
  border: none;
  background: none;
  color: #2699fb;
}

.Head-relink-btn {
  border: none;
  background: #fff;
}

.Filter-relink-btn {
  border: none;
  background: #055cab;
}

.wrapper .is-sticky .navigation .sf-menu .control-btn-login {
  top: 1px;
}

.sf-menu.nav > li > .control-btn-login:hover:before {
  background-color: unset;
}

.sf-menu.nav > li > .control-btn-login:hover {
  background: #fff;
  color: #01adef;
  border-color: #01adef;
}

.pagination {
  margin: 0;
}

.control-sub-our {
  text-align: center;
}

.control-sub-our li {
  border: 1px solid #000;
}

/* Sub Menu */
.sf-menu ul {
  min-width: 8em;
}

.navigation .nav.sf-menu {
  font-family: "Prompt";
}

.page-section {
  padding-top: 50px;
  padding-bottom: 30px;
}

.control-form-check-return.has-label label {
  font-size: 13px;
}

.form-control-icon {
  padding-top: 2px;
}

.info-header .set-pad-info {
  margin: 0 15px 0 0;
  background: #01adef;
  font-weight: 600;
  font-size: 25px;
}

.info .set-pad-info .info-content-a strong {
  font-size: 17px;
}

.info .set-pad-info .info-content-b strong,
.page-a .related-hr .related-title strong {
  font-size: 20px;
}

.info .set-pad-info .info-content-a p {
  font-size: 14px;
}

.page-a .row {
  margin-bottom: 29px;
}

.main-slider .caption {
  position: unset;
}

.main-booking-form .form-search .form-title {
  margin-bottom: 0;
}

.main-slider .item {
  height: 450px;
}

.dark .container .hr-for-mobile-footer {
  display: none;
}

.BookContentA-h1,
.BookContentA-h2,
.BookContentA-h3 {
  font-family: "Prompt";
}

.BookContentA-h1 {
  font-size: 20px;
}

.BookContentA-h2 {
  font-size: 15px;
}

.BookContentA-con-span {
  font-size: 12px;
}

.BookContentA-del-l-r-pad {
  padding-left: 0;
  padding-right: 0;
}

.BookContentA-logo-mar-r {
  margin-right: 5px;
}

.BookContentA-pad-trans {
  padding-left: 7px;
}

.BookContentA-con-btna,
.BookContentA-con-btnb {
  font-size: 12px;
  border-radius: 5px;
  color: #fff;
  margin-top: 25px;
}

.BookContentA-con-btna {
  background: #055cab;
  border: 1px solid #055cab;
  padding: 6px 21px;
}

.BookContentA-con-btnb {
  background: #01adef;
  border: 1px solid #01adef;
  padding: 6px 18px;
}

.row .col-xs-6.BookContentA-mart-pay {
  text-align: right;
  margin-top: 60px;
  padding: 0 15px 0 5px;
}

.BookContentA-set-perday {
  font-size: 13px;
  color: #999;
}

.BookHeaderStatus-circle {
  width: 26px;
  display: inline-block;
  margin: 0 7px;
}

.row .col-xs-3.BookHeaderStatus-set-cen {
  text-align: center;
  margin-top: 35px;
}

.BookHeaderStatus-con-span {
  color: #fff;
  font-size: 16px;
}

.BookContentA-pickre-hide {
  display: none;
}

.BookContentA-p-pick-re {
  color: #999;
}

.BookContentA-logo-trans {
  margin-right: 11px;
  height: 25px;
}

/* .fa-chevron-right {
  color: #fff;
} */
.BookingStep3-hr {
  height: 1px;
  width: 100%;
  background: #999;
  opacity: 0.3;
  margin: 10px 0;
}

.BookingStep3-marr {
  width: 25px;
  margin-right: 10px;
}

.BookingStep3-span-head {
  font-size: 20px;
  font-weight: 600;
}

.BookingStep3-con-spanh {
  font-size: 15px;
}

.BookingStep3-con-span {
  font-size: 12px;
}

.BookingStep3-con-spanh,
.BookingStep3-con-span {
  color: #999;
}

.BookingStep3-marb-pro {
  margin-bottom: 15px;
}

.Book3ContentA-flo-mar {
  float: right;
  margin-top: 5px;
}

.Book3ContentA-flo-mar5 {
  float: right;
  margin-top: 5px;
}

.Book3ContentA-head-option {
  background: #055cab;
  color: #fff;
  padding: 15px 30px;
  font-size: 19px;
  font-weight: 600;
}

.Book3ContentA-set-select {
  border-color: #bce0fd;
  color: #01adef;
  font-size: 13px;
  margin-left: 5px;
  background: #fff;
}

.radio label::after {
  width: 7px;
  height: 7px;
  left: 5px;
  top: 5px;
  background-color: #01adef;
}

.Book3ContentA-input-code,
.Book3ContentA-input-value {
  border: 1px solid #bce0fd;
  color: #01adef;
  padding: 5px 20px;
  font-size: 12px;
}

.Book3ContentA-input-code {
  width: 100%;
}

.Book3ContentA-input-value {
  width: 100%;
}

.Book3ContentA-con-btna,
.Book3ContentA-con-btnb {
  color: #fff;
  padding: 8px 20px;
  font-size: 10px;
  margin-right: 5px;
}

.Book3ContentA-con-btna {
  background: #01adef;
  border: 1px solid #01adef;
}

.Book3ContentA-con-btnb {
  background: #ee4231;
  border: 1px solid #ee4231;
}

.Book3ContentA-chk-voucher {
  margin-left: 10px;
}

.container .row .BookingStep3-set-mar {
  margin-top: 0;
}

.BookingStep3-re-padl {
  padding-left: 0;
  padding-right: 0;
}

.Book3ContentA-head-side-car,
.Book3ContentA-head-side-ren,
.Book3ContentA-head-side-rate,
.Book3ContentA-head-side-total {
  color: #fff;
  padding: 10px 15px;
  margin-bottom: 0;
}

.Book3ContentA-head-side-car {
  background: #055cab;
}

.Book3ContentA-head-side-ren,
.Book3ContentA-head-side-rate {
  background: #a8a9ac;
}

.Book3ContentA-head-side-total {
  background: #01adef;
}

.Book3ContentA-mar-info {
  margin-left: 15px;
  margin-bottom: 0;
  font-size: 12px;
  padding: 20px 0;
}

.Book3ContentA-span-blue {
  color: #01adef;
}

.Book3ContentA-span-red {
  color: #ee4231;
}

.Book3ContentA-span-gray {
  color: #a8a9ac;
}

.Book3ContentA-td-price {
  width: 100px;
  text-align: right;
  color: #a8a9ac;
}

.Book3ContentA-td-unit {
  text-align: right;
  width: 30px;
  color: #a8a9ac;
}

.Book3ContentA-mo-btna,
.Book3ContentA-mo-btnb {
  font-size: 12px;
  padding: 7px 40px;
  border-radius: 5px;
}

.Book3ContentA-img-marr {
  margin-right: 10px;
  margin-left: 5px;
}

.Book3ContentA-pad-info {
  padding: 0 30px 0 15px;
}

.Book3ContentA-pad-info2 {
  padding: 0 30px;
}

.Book3ContentA-size-dot {
  background: #01adef;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  float: left;
  margin-top: 6px;
}

.Book3ContentA-pad-info .row .Book3ContentA-remart-vouc {
  margin-top: 15px;
}

.Book3ContentA-img-term {
  width: 30px;
  margin-right: 10px;
}

.Book3ContentA-martb-dont {
  margin: 20px 0;
}

.col-md-9.BookingStep3-re-padl.BookingStep3-mart-choos {
  margin-top: -260px;
  font-size: 12px;
}

.row .col-md-9.BookingStep3-re-padl.BookingStep3-mart-ext {
  margin-top: -52px;
  font-size: 12px;
}

.Book3ContentA-btnfoot-content {
  width: 100%;
  margin-top: 40px;
  margin-left: -25px;
}

.Book3ContentA-widchkb {
  float: left;
}

.Book3ContentA-linkfoot-cont {
  float: left;
  margin-left: 90px;
  font-size: 15px;
  margin-top: 12px;
}

.Book3ContentA-con-remark {
  font-size: 13px;
}

.Book3ContentA-lorem-term {
  font-size: 12px;
}

.Book3ContentA-span-chkb {
  color: #818285;
  font-size: 14px;
}

.wrapper .header-wrapper .navigation {
  display: block;
}

.Book3ContentA-repad-infos {
  padding-left: 0;
  padding-right: 0;
}

.Book3ContentA-head-option {
  display: flex;
}

.Book3ContentA-remart-sldw,
.Book3ContentA-pad-info2,
.Book3ContentA-pad-info {
  margin-bottom: 50px;
}

.Book3ContentA-btnfoot-content {
  margin-top: 65px;
}

.BookHeaderStatus-setpadb-conarea {
  background: #fff;
}

.Book3ContentA-repad-conside {
  padding-right: 0;
}

.Book3ContentA-mar-info {
  margin-right: 15px;
}

.BookHeaderStatus-row {
  margin: 35px 0 20px 0;
  background: #01adef;
  height: 110px;
}

.checkbox.Home-conmart-same > label {
  color: #055cab;
}

/* CheckBox */
.checkbox .cr {
  position: relative;
  display: inline-block;
  border: 1px solid #01adef;
  width: 1.3em;
  height: 1.3em;
  margin-right: 0.5em;
}

.checkbox label::before {
  display: none;
}

.checkbox label input[type="checkbox"],
.radio label input[type="radio"] {
  display: none;
}

.checkbox label input[type="checkbox"] + .cr > .cr-icon,
.radio label input[type="radio"] + .cr > .cr-icon {
  transform: scale(3) rotateZ(-20deg);
  opacity: 0;
  transition: all 0.3s ease-in;
}

.checkbox label input[type="checkbox"]:checked + .cr > .cr-icon,
.radio label input[type="radio"]:checked + .cr > .cr-icon {
  transform: scale(1) rotateZ(0deg);
  opacity: 1;
}

/* !CheckBox */

strong {
  font-weight: 600;
  font-family: "Prompt";
}

.has-label label {
  font-size: 12px;
  color: #fff;
}

.form-group .form-control {
  font-size: 14px;
  height: 20px;
}

.main-booking-form .form-search .form-control {
  height: 40px;
}

.main-booking-form .ver3 .form-search {
  background-color: #055cab;
  /* all: unset; */
}

.form-title .row-inputs {
  font-size: 12px;
}

.form-title .row-inputs .set-pad {
  padding-left: 0;
}

.form-title .row-inputs .set-pad-space {
  padding-left: 29px;
}

.form-title .row-inputs .set-pad-space {
  padding-left: 29px;
}

.form-title .set-mar {
  margin: 0;
}

.form-title .set-mar .set-pad {
  padding: 0;
}

.main-slider .ver3 .form-search .container-title {
  text-align: center;
  width: 100%;
  padding-top: 170px;
}

.container-title h1 {
  color: #fff;
  font-size: 70px;
}

.container-title .hr-promo {
  background: #fff;
  height: 7px;
  min-width: 126px;
  display: inline-block;
  margin-left: 11px;
}

.form-title .row-inputs .no-label {
  top: 21px;
}

.form-title .row-inputs .no-label1 {
  top: 17px;
}

.control-form-pickup {
  position: relative;
}

.content-area .page-section .set-min {
  background: #fff;
  box-shadow: #444;
}

.set-btn-slide a {
  padding: 10px 30px;
  background: #01adef;
  color: #fff;
  border-radius: 10px;
}

.main-booking-form .form-search .form-group,
.wrap-form-filter .form-search .form-group {
  float: left;
}

.main-booking-form .form-search .has-icon .form-control,
.wrap-form-filter .form-search .has-icon .form-control {
  padding-right: 7px;
}

.main-booking-form .form-search .control-form-lo,
.wrap-form-filter .form-search .control-form-lo {
  width: 14%;
  margin-right: 10px;
}

.main-booking-form .form-search .control-form-pick,
.wrap-form-filter .form-search .control-form-pick {
  width: 11%;
  margin-right: 10px;
}

/* .main-slider .form-search .control-form-timea {
} */
.main-booking-form .form-search .control-form-timea .control-timea-hr,
.main-booking-form .form-search .control-form-timea .control-timea-min,
.wrap-form-filter .form-search .control-form-timea .control-timea-hr,
.wrap-form-filter .form-search .control-form-timea .control-timea-min {
  float: left;
  margin-right: 10px;
}

.main-booking-form .form-search .has-icon .control-timea-hr .form-control,
.main-booking-form .form-search .has-icon .control-timea-min .form-control,
.wrap-form-filter .form-search .has-icon .control-timea-hr .form-control,
.wrap-form-filter .form-search .has-icon .control-timea-min .form-control {
  padding-right: 0;
}

.main-slider .form-search .control-form-select,
.wrap-form-filter .form-search .control-form-select {
  width: 14%;
  top: 25px;
  margin-right: 10px;
}

.main-slider .form-search .control-form-return,
.wrap-form-filter .form-search .control-form-return {
  width: 11%;
  margin-right: 10px;
}

.main-slider .form-search .control-form-timeb .control-timeb-hr,
.main-slider .form-search .control-form-timeb .control-timeb-min,
.wrap-form-filter .form-search .control-form-timeb .control-timeb-hr,
.wrap-form-filter .form-search .control-form-timeb .control-timeb-min {
  float: left;
  margin-right: 10px;
}

.main-slider .form-search .has-icon .control-timeb-hr .form-control,
.main-slider .form-search .has-icon .control-timeb-min .form-control,
.wrap-form-filter .form-search .has-icon .control-timeb-hr .form-control,
.wrap-form-filter .form-search .has-icon .control-timeb-min .form-control {
  padding-right: 0;
}

.main-slider .form-search .control-form-check,
.wrap-form-filter .form-search .control-form-check {
  width: 12%;
  top: 25px;
  margin-right: 10px;
}

.wrap-form-filter .form-search .control-form-check {
  width: 10%;
}

.main-slider .form-search .control-form-btn,
.wrap-form-filter .form-search .control-form-btn {
  top: 25px;
  /*width: 12%;*/
  margin-right: 0;
  /* float: left; */
}

.main-slider .form-search .control-form-btn .control-btn-refresh,
.wrap-form-filter .form-search .control-form-btn .control-btn-refresh {
  border-radius: 21px;
  padding-left: 16px;
  padding-right: 3px;
  padding-top: 10px;
  width: 100%;
}

.main-slider .form-search .form-control-space,
.wrap-form-filter .form-search .form-control-space {
  border-radius: 16px;
  height: 36px;
  width: 36px;
  background-color: #fff;
  border-left: 1px solid #fff;
}

.main-slider .form-search.light .form-title .fa-refresh,
.wrap-form-filter .form-search.light .form-title .fa-refresh {
  margin-left: 5px;
}

.btn-submit .btn-theme-dark {
  border-radius: 5px;
  width: 150px;
}

/* prev & next */
.pagination > li > a {
  border: unset;
}

.pagination .page-item .page-link.set-border-prev {
  border: 1px solid #dee2e6;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.pagination .set-border1 {
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}

.pagination .set-border2 {
  border: 1px solid #dee2e6;
}

.pagination .set-border3 {
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}

.pagination .set-border1,
.pagination .set-border3 {
  border: none;
}

.pagination .page-item .page-link.set-border-next {
  border: 1px solid #dee2e6;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.pagination .page-item .page-link {
  background: #fff;
  color: #01adef;
  margin: 30px 0 0 0;
  padding: 10px;
  width: 29px;
  border-top: 2px solid #fff;
}

.pagination .page-item .page-link:hover {
  background: #d5dcd8;
}

.pagination .set-border1:hover {
  border-color: #d5dcd8;
}

.pagination .set-border2:hover {
  border-color: #d5dcd8;
}

.pagination .set-border3:hover {
  border-color: #d5dcd8;
}

.navigation .nav.sf-menu {
  line-height: 1.7;
  font-size: 14px;
}

.Filter-confont-same {
  color: #fff;
  font-size: 13px;
  padding-left: 5px;
}

.Filter-conmart-same {
  margin-top: -5px;
  padding-left: 20px;
  padding-top: 0px;
  margin-bottom: 0;
}

.checkbox.Filter-conmart-same .cr {
  background: #fff;
  /* border-radius: 50%; */
  width: 20px;
  height: 20px;
  border: 1.2px solid #999;
}

.checkbox.Filter-conmart-same .cr .glyphicon-ok {
  top: 1px !important;
  left: 1px;
}

/* .pagination > li > a > span:hover {
  border-color: unset; 
} */
/* .pagination > li > a:hover {
  border-color: unset; 
} */

/* /prev & next */

.main-slider
  .form-search
  .form-title
  .row-inputs
  .control-form-group-timea
  .hr-for-mobile {
  display: none;
  height: 1px;
  width: 96%;
  background: #fff;
}

.main-slider .form-search.light .form-title .fa-check {
  margin: 0;
  font-size: 17px;
}

.main-slider .form-search .form-control-icon .fa-check span {
  font-size: 8px;
  padding-bottom: 2px;
}

.main-slider .form-search .form-control-space i {
  margin: 0;
}

.page-a {
  padding-top: 40px;
}

.page-a .set-pad {
  padding: 0;
}

.ifo-header {
  background: #01adef;
  padding: 10px 30px;
}

.info-header h3 {
  color: #fff;
}

.set-pad-info {
  padding: 20px 30px 23px;
  background: #fff;
  margin-right: 15px;
}

.info-hr {
  width: 100%;
  height: 1px;
  background-color: #444;
}

.info-content-a {
  padding-bottom: 15px;
}

.info .info-content-a .control-p,
.info .info-content-b .control-p {
  font-size: 13px;
}

.info-content-b h4 {
  color: #01adef;
  padding: 15px 0;
}

.related-hr {
  margin: 0;
}

.related-hr .hr-left {
  height: 2px;
  background: #01adef;
}

.related-hr .related-title {
  text-align: center;
  top: 18px;
  color: #01adef;
}

.related-hr .hr-right {
  height: 2px;
  background: #01adef;
}

.related-img {
  margin: 0;
  padding: 0 10px;
  width: 100%;
}

.set-pad-related {
  padding: 5px;
}

.control-detail-related {
  text-align: center;
  background: #fff;
  padding: 0 21px;
  font-size: 14px;
}

.control-detail-related a {
  border: 1px solid #01adef;
  padding: 10px 30px;
  background: #01adef;
  color: #fff;
  border-radius: 10px;
  display: inline-block;
  margin-bottom: 10px;
}

@media (max-width: 490px) {
  .control-detail-related a {
    padding: 5px 15px;
    font-size: 12px;
  }
}

.navigation ul.social-icons {
  padding-top: 28.5px;
}

.wrapper .logo span {
  font-size: 27px;
  color: #055cab;
}

.wrapper .logo .fa-phone {
  font-size: 30px;
}

.wrapper .control-phone-header {
  top: 2px;
  margin-left: 142px;
}

.page-a .related-img .set-pad-related {
  margin-top: 0;
}

.control-detail-related a:hover {
  border: 1px solid #01adef;
  color: #01adef;
  background: #fff;
}

.Filter-showhidefilter {
  float: right;
  margin-right: 6px;
  margin-top: 10px;
  display: none;
  opacity: 0.6;
  transition: 0.3s;
}

.Filter-showhidefilter:hover {
  transform: scale(1.3);
  opacity: 1;
}

.main-slider
  .form-search.light
  .form-title
  .Filter-showhidefilter
  .Filter-confont-updown {
  font-size: 30px;
}

/* .main-slider
    .form-search.light
    .form-title
    .Filter-showhidefilter
    .Filter-confont-updown:hover {
    
} */

.Filter-setpad-infoform {
  padding: 0;
  background: #055cab;
}

/* .Filter-repad-contain {
    display: none;
} */
.form-search .Filter-confont-labelform {
  color: #fff;
  font-weight: 500;
}

.main-slider .form-search .form-control-space.HomeFilter-conpo-iconchk {
  top: 27px;
  width: 22px;
  right: 8px;
}

.label-same-location {
  right: 25px;
}

@media (min-width: 1199px) {
  .swiper-wrapper .sf-menu.nav > li > a:hover {
    /* transform: scale(1.3); */
  }

  .swiper-wrapper .sf-menu.nav > li > #myInput1:hover {
    transform: scale(1);
  }

  .Filter-repad-contain {
    display: none !important;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker-popper {
    top: -110px !important;
    left: -14px !important;
  }

  .is-sticky .sf-menu .Header-conw-subour {
    padding-top: 0px;
  }

  .is-sticky .sf-menu .Header-conw-subour.Header-conw-mosubloged {
    padding-top: 0px;
  }

  .is-sticky .header-wrapper .navigation {
    padding-top: 0px;
  }

  .Filter-conmart-same {
    margin-top: 15px;
  }
}

@media (max-width: 1200px) {
  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker-popper {
    top: -4px !important;
    left: -1px !important;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__input-container {
    width: 100%;
  }

  .Filter-showhidefilter {
    display: block;
  }

  .Filter-hidemobile-active {
    display: none;
  }

  .form-title .row-inputs .no-label {
    top: 21px;
  }

  /* .menu-toggle {
        display: inline-block;
        padding-top: 16px;
        margin: 0;
        height: 64px;
        width: 58px;
        font-size: 28px;
        background: #01adef;
        border: 1px solid #01adef;
    } */
  .menu-toggle .fa-bars {
    color: #fff;
  }

  .wrapper .menu-toggle .fa-bars:hover {
    color: #01adef;
  }

  .btn-theme-transparent:hover {
    background-color: #01adef;
    border-color: #01adef;
    color: #01adef;
  }

  .btn-theme-transparent:focus {
    background-color: #01adef;
  }

  .logo {
    line-height: 65px;
  }

  .header .header-wrapper > .container {
    min-height: 64px;
  }

  .wrapper .is-sticky .logo {
    line-height: 85px;
  }

  .is-sticky .menu-toggle {
    height: unset;
    padding-top: 10px;
    padding-bottom: 4px;
  }

  .is-sticky .header .header-wrapper > .container {
    min-height: 55px;
  }

  .BookContentA-con-btna {
    padding: 6px 19px;
  }

  .BookContentA-con-btnb {
    padding: 6px 17px;
  }

  .BookingStep3-span-head {
    font-size: 15px;
  }

  .BookingStep3-con-spanh {
    font-size: 13px;
  }

  .BookingStep3-con-span {
    font-size: 11px;
  }

  .BookingStep3-marr {
    width: 20px;
    margin-right: 6px;
  }

  .BookingStep3-repadrl-pro {
    padding-left: 0;
    padding-right: 0;
  }

  .col-md-9.BookingStep3-re-padl.BookingStep3-mart-choos {
    margin-top: -415px;
    font-size: 10px;
  }

  .row .col-md-9.BookingStep3-re-padl.BookingStep3-mart-ext {
    margin-top: -206px;
  }

  .Book3ContentA-head-option {
    font-size: 15px;
  }

  .Book3ContentA-img-marr {
    width: 30px;
  }

  .Book3ContentA-mobile-chk {
    width: 40%;
  }

  .Book3ContentA-td-price {
    width: 6.5%;
  }

  .Book3ContentA-repad-infos {
    font-size: 12px;
  }

  .Book3ContentA-remart-sldw {
    padding-right: 0;
  }

  .main-slider .form-search .control-form-btn {
    width: 50%;
  }

  .control-btn-refresh {
    font-size: 18px;
  }

  .page-section
    .main-slider
    .form-search.light
    .form-title
    .Filter-conicon-date {
    top: 28.5px;
  }

  .page-section
    .main-slider
    .form-search.light
    .form-title
    .Filter-conicon-date.Filter-conicon-datemo {
    top: 29px;
  }

  .main-slider .form-search .control-form-check-a {
    top: 29px;
    width: 100%;
  }

  .main-slider .form-search .form-control-space {
    width: 42px;
  }

  .re-form-con::placeholder {
    color: #bce0fd !important;
  }

  .form-search .Filter-confont-labelform {
    font-size: 16px;
  }
}

@media (max-width: 991px) {
  .logo img {
    height: 45px;
  }

  .HomeFilter-conreactdate-mo .react-datepicker-popper {
    top: 120px !important;
  }

  .sf-menu.nav > li > .control-btn-login:hover {
    border-radius: unset;
    border-color: #fff;
    padding-right: 65px;
  }
  #btnlogin-menuid {
    text-align: center;
  }
  #btnlogin-menuid.active a {
    background: none !important;
  }
  .sf-menu.nav > li.active > a {
    background: #fff;
    color: #055cab !important;
    margin-bottom: 10px;
  }

  .page-section
    .main-slider
    .form-search.light
    .form-title
    .Filter-conicon-date.Filter-conicon-datemo {
    top: 28.5px;
  }

  .swiper-slide .sf-menu .Header-conw-subour.Header-conw-mosublagu {
    display: flex !important;
    margin-top: 20px;
  }

  .nav.sf-menu .Header-conwpad-social {
    display: none;
  }

  .Header-conw-subour.Header-conw-mosublagu li {
    padding-left: 15px;
    margin-bottom: 0 !important;
  }

  .swiper-slide .sf-menu .Header-conw-subour,
  .swiper-slide .sf-menu .Header-conw-mosubcom,
  .swiper-slide .sf-menu .Header-conw-mosubcon,
  .swiper-slide .sf-menu .Header-conw-mosubloged {
    display: none !important;
  }

  /* a:not(.Header-con-menumobile):active,
    a:not(.Header-con-menumobile):focus {
        color: #fff;
    } */
  a.Header-con-menumobile:active,
  a.Header-con-menumobile:focus {
    color: #fff;
  }

  .swiper-slide .sf-menu .Header-conw-mosubour.Header-conw-mosubour-show {
    display: block !important;
  }

  .swiper-slide .sf-menu .Header-conw-mosubcom.Header-conw-mosubcom-show {
    display: block !important;
  }

  .swiper-slide .sf-menu .Header-conw-mosubcon.Header-conw-mosubcon-show {
    display: block !important;
  }

  .Header-conw-subour {
    margin-left: 40px;
  }

  .Header-conw-subour li {
    padding: 0 15px 5px;
    color: #fff;
    padding-left: 40px;
  }

  .Header-conw-subour li span {
    border-bottom: 5px solid #14181c;
  }

  .wrapper .navigation .sf-menu .control-btn-login {
    top: 5px;
    margin-top: 5px;
    width: 90%;
  }

  .Head-relink-btn {
    background: #14181c;
  }

  .main-slider .form-search .control-form-btn {
    width: 100%;
  }

  .main-slider .ver3 .form-search {
    width: 100%;
  }

  .form-title .row-inputs .no-label {
    top: 21px;
  }

  .control-pagination {
    text-align: center;
  }

  .wrapper .control-phone-header {
    margin-left: 120px;
  }

  .wrapper .is-sticky .logo {
    line-height: 56px;
  }

  .page-section .row .form-footer .btn-submit input {
    width: 70%;
    height: 60px;
    font-size: 20px;
  }

  .page-section .row .form-footer .btn-submit {
    text-align: center;
  }

  .content-area .page-section .hide-mobile {
    display: none;
  }

  .page-section .row .copy-footer {
    text-align: center;
    margin-bottom: 40px;
  }

  .page-section .row .copy-footer .social-icons {
    display: inline-block;
  }

  .page-section .row .menu-footer {
    display: none;
  }

  .page-section .row .form-footer .form-sub {
    display: none;
  }

  .content-area .dark .container .hr-for-mobile-footer {
    display: block;
    background: #999;
    height: 2px;
    margin: 0 15px;
  }

  .content-area .dark .container-fluid .copy-footer .logo-footer-th {
    margin-bottom: 40px;
    width: 280px;
  }

  .BookContentA-pickre-hide {
    display: block;
  }

  .BookContentA-pad-mobile {
    padding-left: 15px;
  }

  .BookContentA-width-th {
    width: 105px;
  }

  .BookContentA-width-td {
    width: 55px;
  }

  .BookContentA-logo-trans {
    height: 30px;
  }

  .row .col-xs-6.BookContentA-mart-pay {
    text-align: center;
    margin-top: 25px;
  }

  .col-md-9.BookingStep3-re-padl.BookingStep3-mart-choos {
    margin-top: 0;
    font-size: 10px;
  }

  .row .col-md-9.BookingStep3-re-padl.BookingStep3-mart-ext {
    margin-top: 0;
  }

  .BookingStep3-mobile-padl {
    padding-left: 30px;
  }

  .Book3ContentA-head-side-car,
  .Book3ContentA-head-side-ren,
  .Book3ContentA-head-side-rate,
  .Book3ContentA-head-side-total {
    margin-right: 0;
    margin-left: 0;
  }

  .Book3ContentA-mar-info {
    margin-right: 30px;
    margin-left: 30px;
  }

  .Book3ContentA-head-option {
    text-align: center;
    margin-left: 0;
    margin-right: 15px;
  }

  .Book3ContentA-remart-sldw {
    padding-right: 15px;
  }

  .Book3ContentA-pad-info {
    margin-left: 30px;
    font-size: 13px;
  }

  .Book3ContentA-pad-info2 {
    margin-left: 30px;
    font-size: 13px;
  }

  .Book3ContentA-mo-padr-vouc {
    padding-right: 10px;
  }

  .row .BookingStep3-re-padl .Book3ContentA-pad-info .Book3ContentA-wtxt-vouc {
    width: 49%;
    margin-top: 15px;
  }

  .Book3ContentA-pad-info .row .Book3ContentA-mobile-chk {
    margin-top: 15px;
  }

  .Book3ContentA-mobile-chk {
    width: 100%;
  }

  .Book3ContentA-con-btna,
  .Book3ContentA-con-btnb {
    padding: 10px;
    padding: 8px 40px;
  }

  .Book3ContentA-btnfoot-content {
    margin-left: 0;
    margin-top: 15px;
  }

  .Book3ContentA-widchkb {
    float: unset;
  }

  .Book3ContentA-linkfoot-cont {
    float: unset;
    margin-left: 0;
  }

  .row .col-md-9 .Book3ContentA-pad-info .Book3ContentA-remart-sldw {
    margin-top: 0;
  }

  .Book3ContentA-float-mobile {
    float: left;
  }

  .Book3ContentA-chk-mobile {
    display: none;
  }

  .Book3ContentA-chk-voucher {
    display: none !important;
  }

  .row .BookingStep3-re-padl .Book3ContentA-pad-info .Book3ContentA-wtxt-vouc {
    width: 100%;
  }

  .Book3ContentA-conchk-mobile {
    margin-left: 220px;
    padding-top: 5px;
  }

  .Book3ContentA-mobile-chk .Book3ContentA-mobtna-vouc {
    width: 49%;
  }

  .Book3ContentA-flo-mar {
    margin-top: 5px;
  }

  .BookingStep3-re-contain {
    padding-right: 0;
    padding-left: 0;
  }

  .BookHeaderStatus-setpadb-conarea {
    padding-bottom: 30px;
  }

  .Book3ContentA-repadchoos-info {
    padding-left: 0;
  }

  .Book3ContentA-remart-vouc {
    padding-left: 0;
  }

  .Book3ContentA-repad-movouc,
  .Book3ContentA-txt-mar,
  .Book3ContentA-repadl-voucV2 {
    padding-left: 0;
  }

  .Book3ContentA-pad-info {
    padding-left: 0;
  }

  .Book3ContentA-pad-info2 {
    padding-left: 0;
  }

  .BookHeaderStatus-setpadb-conarea {
    background: unset;
  }

  .BookingStep3-setgbw-make {
    background: #fff;
    margin: 0 15px 0 0;
    padding: 30px;
  }

  .Book3ContentA-setbgW {
    background: #fff;
    margin-right: 15px;
  }

  .Book3ContentA-mo-setbgW {
    margin-right: 30px;
    margin-left: 15px;
  }

  .Book3ContentA-head-side-total {
    margin-right: 30px;
    margin-left: 15px;
  }

  .Book3ContentA-pad-info2,
  .Book3ContentA-pad-info {
    padding-bottom: 30px;
  }

  .Book3ContentA-remart-sldw {
    margin-bottom: 0;
  }

  /*//////////////////*/
  .menu-toggle {
    display: inline-block;
    padding-top: 16px;
    margin: 0;
    height: 64px;
    width: 58px;
    font-size: 28px;
    background: #01adef;
    border: 1px solid #01adef;
  }

  .menu-toggle-close .fa {
    display: inline-block;
    text-align: center;
    width: 30px;
    height: 30px;
    font-size: 15px;
    line-height: 28px;
    color: #ffffff !important;
    background-color: #14181c;
    border: solid 1px #ffffff;
    border-radius: 15px;
  }

  .fa-remove:before,
  .fa-close:before,
  .fa-times:before {
    content: "\f00d";
  }

  .menu-toggle-close {
    display: block;
    width: 100%;
    text-align: right;
  }

  .wrapper .header-wrapper .navigation {
    display: block;
    right: -255px;
  }
}

@media (max-width: 779px) {
  .wrap_step_txt {
    display: none;
  }

  .page-section .row .copy-footer {
    text-align: center;
  }

  .page-section .row .copy-footer .social-icons {
    display: inline-block;
  }

  .page-section .row .copy-footer .social-icons a {
    height: 50px;
    width: 50px;
  }

  .dark #contact-form .form-control {
    font-size: 13px;
  }
}

@media (max-width: 768px) {
  .set-container-mobile {
    padding-left: 0;
    padding-right: 0;
  }

  .glyphicon.glyphicon-chevron-left {
    right: 8px;
  }

  .glyphicon.glyphicon-chevron-right {
    right: 3px;
  }

  .main-slider .form-search .form-control-space {
    top: 2px;
  }

  .main-slider .form-search.light .form-title .fa.Filter-confonticon-chk {
    font-size: 27px;
  }

  .Filter-repad-containbook {
    padding: 0;
  }

  .page-section
    .main-slider
    .form-search.light
    .form-title
    .Filter-conicon-date
    .fa {
    font-size: 24px;
  }

  .page-section
    .main-slider
    .form-search
    .row-inputs
    .container-fluid
    .Filter-repadlr-input {
    padding: 0;
  }

  .page-section
    .main-slider
    .form-search
    .row-inputs
    .container-fluid
    .Filter-repadr-input {
    padding-right: 0;
  }

  .content-area
    .main-slider
    .form-search
    .form-title
    .Filter-repad-contain
    .reset-col {
    padding: 0;
  }

  .Filter-repad-contain {
    padding: 0 20px 0 0;
    margin-right: 0;
  }

  .main-slider .form-search .row-inputs .Filter-repadcon-fluid {
    padding: 0;
  }

  .Filter-conw-colrefresh {
    width: 100%;
  }

  .page-a .img-mobile {
  }

  .main-slider .item .form-title .con-book {
    text-align: center;
  }

  .main-slider .form-search.light .form-title h2 {
    max-width: unset;
  }

  .main-slider .item {
    min-height: 450px;
  }

  .main-slider .item .container-title strong {
    font-size: 50px;
  }

  .glyphicon-chevron-left:before {
    margin-left: 6px;
  }

  .main-slider .form-search .row,
  .main-slider .form-search .row div[class*="col-"] {
    float: left;
    margin: 0;
    padding: 0;
  }

  .main-slider .form-search .form-title .row-inputs .control-form-group-loca {
    width: 56%;
    margin-right: 30px;
  }

  .main-slider .form-search .form-title .row-inputs .control-form-group-date {
    width: 34%;
  }

  .main-slider
    .form-search
    .form-title
    .row-inputs
    .form-group
    .control-form-pickup
    input {
    padding-left: 14px;
  }

  .main-slider .form-search .form-title .row-inputs .control-form-group-timea {
    width: 100%;
  }

  .main-slider .form-search .form-title .row-inputs .control-form-group-select {
    width: 56%;
    margin-right: 30px;
    margin-top: 20px;
  }

  .main-slider
    .form-search
    .form-title
    .row-inputs
    .control-form-group-timea
    .control-select,
  .main-slider
    .form-search
    .form-title
    .row-inputs
    .control-form-group-timeb
    .control-select {
    margin-right: 10px;
  }

  .main-slider .form-search .form-title .row-inputs .control-form-group-timeb {
    width: 47%;
    margin-right: 10px;
  }

  .main-slider .form-search .form-title .row-inputs .control-form-group-return {
    width: 34%;
    margin-top: 19px;
  }

  .main-slider .form-search .form-title .row-inputs .control-form-group-check {
    width: 48%;
    top: 1px;
  }

  .main-slider
    .form-search
    .form-title
    .row-inputs
    .control-form-group-refresh {
    width: 100%;
    text-align: center;
    margin-top: 50px;
  }

  .main-slider
    .form-search
    .form-title
    .row-inputs
    .control-form-group-refresh
    .control-btn-refresh {
    border-radius: 100px;
    height: 49px;
    width: 80%;
    font-size: 16px;
    background: #ea2b2b;
  }

  .main-slider
    .form-search
    .form-title
    .row-inputs
    .control-form-group-timea
    .hr-for-mobile {
    display: block;
    margin: 80px 0px 0 0;
  }

  .main-slider .form-search .form-title .row-inputs .control-form-group-radio {
    position: relative;
    top: -90px;
  }

  .has-label label {
    font-size: 12px;
  }

  .form-group .form-control {
    font-size: 13px;
  }

  .main-slider .form-search .has-icon .form-control {
    padding-right: 0px;
  }

  .main-slider .form-search.light .form-title .fa {
    font-size: 15px;
  }

  .logo {
    margin-left: 20px;
  }

  .wrapper .control-phone-header {
    margin-left: 134px;
  }

  .header .header-wrapper > .container {
    padding: 0px 32px;
  }

  .main-slider .ver3 .form-search .container-title {
    padding-top: 76px;
  }

  .related-hr .hr-left,
  .related-hr .related-title,
  .related-hr .hr-right {
    float: left;
  }

  .related-hr .hr-left,
  .related-hr .hr-right {
    width: 27%;
  }

  .related-hr .related-title {
    width: 40%;
  }

  .set-pad-related {
    float: left;
    width: 50%;
  }

  .info-header .set-pad-info {
    margin: 0 15px;
  }

  .info .set-pad-info {
    margin: 0 15px;
  }

  .dark .copy-footer .ex-img {
    width: 35%;
  }

  /* .fa-chevron-right {
    width: 23px;
    font-size: 10px;
  } */
  .BookHeaderStatus-circle {
    margin: 0 3px;
  }

  /* .row .col-xs-10.BookHeaderStatus-reset-col {
        width: 100%;
        padding-right: 0;
        padding-left: 0;
        margin-top: 0;
    } */
  .Book3ContentA-btnfoot-content {
    text-align: center;
  }

  .Book3ContentA-con-btna,
  .Book3ContentA-con-btnb {
    width: 48%;
  }

  .Book3ContentA-mobile-chk .Book3ContentA-mobtna-vouc {
    width: 45%;
  }

  .Book3ContentA-flo-mar {
    margin-top: 5px;
  }

  .Book3ContentA-input-code {
    width: 91%;
  }

  .Book3ContentA-span-chkb {
    font-size: 12px;
  }

  .Book3ContentA-linkfoot-cont {
    font-size: 10px;
  }

  .BookingStep3-repad-contain {
    padding-right: 0;
  }

  .BookingStep3-repadl-contain {
    padding-left: 15px;
  }

  .Book3ContentA-head-side-car,
  .Book3ContentA-head-side-ren,
  .Book3ContentA-head-side-rate,
  .Book3ContentA-head-side-total {
    margin-right: 0;
    margin-left: 0;
  }

  .Book3ContentA-head-option {
    margin-right: 0;
  }

  .BookHeaderStatus-repad-statusbar {
    padding-right: 0;
    padding-left: 0;
  }

  .Book3ContentA-repad-conside {
    padding-right: 15px;
  }

  .Book3ContentA-mo-setbgW {
    margin-right: 0;
    margin-left: 0;
  }

  .Book3ContentA-setbgW {
    margin-right: 0;
  }

  .BookingStep3-setgbw-make {
    margin-right: 0;
  }

  .label-same-location {
    right: -5px;
  }
}

@media (max-width: 767px) {
  .Filter-repad-contain {
    padding: 0;
  }
}

@media (max-width: 680px) {
  .react-datepicker-popper {
    left: -30px !important;
  }

  .HomeFilter-conreactpicdate .react-datepicker-popper {
    left: 0px !important;
    top: -110px !important;
  }
}

@media (max-width: 570px) {
  .page-a .related-hr .related-title strong {
    font-size: 18px;
  }

  .react-datepicker-popper {
    left: -70px !important;
  }
}

@media (max-width: 572px) {
}

@media (max-width: 546px) {
  .related-hr .related-title {
    width: 45%;
  }

  .page-a .related-hr .related-title strong {
    font-size: 14px;
  }

  .BookContentA-space-mobile {
    width: 25%;
    padding-right: 0;
  }

  .BookContentA-width-logo {
    width: 19%;
  }

  .BookContentA-con-span {
    font-size: 10px;
  }

  .BookContentA-logo-trans {
    height: 20px;
  }

  .row .col-xs-6.BookContentA-mart-pay {
    text-align: right;
  }

  .row .col-xs-6.BookContentA-mart-pay.right {
    padding-right: 45px;
  }

  .BookContentA-con-btna,
  .BookContentA-con-btnb {
    width: 120px;
  }

  .BookHeaderStatus-img-mobile {
    width: 80%;
  }

  .BookHeaderStatus-set-cen {
    padding: 0;
  }

  .row .col-xs-3.BookHeaderStatus-set-cen {
    text-align: center;
  }

  /* .fa-chevron-right {
    width: 7px;
  } */
  .BookHeaderStatus-circle {
    margin: 0 0 0 3px;
  }

  .Book3ContentA-input-value {
    width: 130px;
  }

  .Book3ContentA-conchk-mobile {
    margin-left: 155px;
    padding-top: 5px;
  }

  .Book3ContentA-mobile-chk .Book3ContentA-mobtna-vouc {
    width: 48%;
  }

  .Book3ContentA-input-codeV2 {
    width: 97%;
  }

  .Book3ContentA-pad-info .row .Book3ContentA-con-remark {
    font-size: 10px;
  }

  .Book3ContentA-tdremark {
    width: 55px;
  }

  .Book3ContentA-con-btna,
  .Book3ContentA-con-btnb {
    width: 44%;
  }

  .Book3ContentA-pad-info {
    font-size: 10px;
  }

  .Book3ContentA-input-code {
    width: 97%;
  }

  .Book3ContentA-head-side-car,
  .Book3ContentA-head-side-ren,
  .Book3ContentA-head-side-rate,
  .Book3ContentA-head-side-total {
    font-size: 13px;
  }

  .Book3ContentA-head-option {
    font-size: 13px;
  }

  .Book3ContentA-pad-info2 {
    font-size: 11px;
  }

  .Book3ContentA-wtxt-vouc,
  .Book3ContentA-repad-movouc,
  .Book3ContentA-mobile-chk,
  .Book3ContentA-remart-vouc,
  .Book3ContentA-con-remark {
    padding-left: 0;
    padding-right: 0;
  }

  .Book3ContentA-lorem-term {
    font-size: 10px;
  }
}

@media (max-width: 490px) {
  .Filter-conw-select {
    width: 95px;
  }

  .Filter-conw-selecta {
    width: 95px;
  }

  .form-search .Filter-confont-labelform {
    font-size: 14px;
  }

  .page-section
    .main-slider
    .form-search.light
    .form-title
    .Filter-conicon-date {
    top: 25.5px;
  }

  .page-section
    .main-slider
    .form-search.light
    .form-title
    .Filter-conicon-date.Filter-conicon-datemo {
    top: 25.5px;
  }

  .main-slider .form-search .control-form-check-a {
    top: 26px;
  }

  .Filter-conw-mobile {
    width: 60%;
  }

  .Filter-conw-inputdate {
    width: 40%;
  }

  .page-section
    .main-slider
    .form-search
    .row-inputs
    .container-fluid
    .Filter-repadr-input.Filter-conw-inputcode {
    padding-right: 0;
    width: 150px;
  }
}

@media (max-width: 450px) {
  .Book3ContentA-mobile-chk .Book3ContentA-mobtna-vouc {
    width: 47%;
  }

  .Book3ContentA-pad-info {
    font-size: 9px;
  }

  .Book3ContentA-flo-mar5 {
    margin-top: 8px;
  }

  .Book3ContentA-flo-mar {
    margin-top: 8px;
  }
}

@media (max-width: 414px) {
  .page-section
    .main-slider
    .form-search
    .row-inputs
    .container-fluid
    .Filter-repadr-input.Filter-conw-inputcode {
    width: 62.46666%;
  }
}

@media (max-width: 413px) {
  .page-section
    .main-slider
    .form-search
    .row-inputs
    .container-fluid
    .Filter-repadr-input.Filter-conw-inputcode {
    width: 150px;
  }
}

@media (max-width: 411px) {
  .page-section
    .main-slider
    .form-search
    .row-inputs
    .container-fluid
    .Filter-repadr-input.Filter-conw-inputcode {
    width: 62.244444%;
  }
}

@media (max-width: 410px) {
  .page-section
    .main-slider
    .form-search
    .row-inputs
    .container-fluid
    .Filter-repadr-input.Filter-conw-inputcode {
    width: 150px;
  }

  .BookContentA-logo-trans {
    height: 17px;
    margin-right: 8px;
  }

  .BookContentA-span-option {
    font-size: 8px;
  }

  .BookContentA-h1 {
    font-size: 19px;
  }

  .Book3ContentA-repadchoos-info {
    padding-left: 0;
    padding-right: 0;
  }

  .Book3ContentA-set-select {
    font-size: 10px;
    width: 16%;
  }

  .Book3ContentA-img-marr {
    margin-right: 5px;
    margin-left: 0;
    width: 25px;
  }

  .Book3ContentA-flo-mar {
    margin-top: 5px;
  }

  .Book3ContentA-flo-mar5 {
    margin-top: 5px;
  }
}

@media (max-width: 405px) {
  .row .BookContentA-mart-pay.left {
    padding: 5px;
  }

  .row .col-xs-6.BookContentA-mart-pay.right {
    padding: 5px;
  }

  .Filter-conw-mobile {
    width: 55%;
  }

  .Filter-conw-inputdate {
    width: 45%;
  }
}

@media (max-width: 375px) {
  .page-section
    .main-slider
    .form-search
    .row-inputs
    .container-fluid
    .Filter-repadr-input.Filter-conw-inputcode {
    width: 58.21111%;
  }
}

@media (max-width: 374px) {
  .page-section
    .main-slider
    .form-search
    .row-inputs
    .container-fluid
    .Filter-repadr-input.Filter-conw-inputcode {
    width: 150px;
  }
}

@media (max-width: 360px) {
  .page-section
    .main-slider
    .form-search
    .row-inputs
    .container-fluid
    .Filter-repadr-input.Filter-conw-inputcode {
    width: 56.24444%;
  }

  .Filter-conw-mobile {
    width: 50%;
  }

  .Filter-conw-inputdate {
    width: 50%;
  }
}

@media (max-width: 359px) {
  .page-section
    .main-slider
    .form-search
    .row-inputs
    .container-fluid
    .Filter-repadr-input.Filter-conw-inputcode {
    width: 150px;
  }
}

@media (max-width: 355px) {
  .wrapper .logo.control-phone-header span {
    font-size: 27px;
  }

  .wrapper .logo.control-phone-header {
    margin-left: 120px;
  }

  .HomeFilter-conreactpicdate .react-datepicker-popper {
    left: -7px !important;
  }

  .page-section
    .main-slider
    .form-search.light
    .form-title
    .Filter-conicon-date {
    top: 24px;
  }

  .page-section
    .main-slider
    .form-search.light
    .form-title
    .Filter-conicon-date.Filter-conicon-datemo {
    top: 23.5px;
  }

  .main-slider .form-search .control-form-check-a {
    top: 23px;
  }

  .Book3ContentA-conchk-mobile {
    margin-left: 110px;
  }

  .Book3ContentA-input-code {
    font-size: 10px;
  }

  .Book3ContentA-input-value {
    font-size: 10px;
    width: 95px;
  }

  .Book3ContentA-mobile-350 {
    padding-top: 3px;
  }

  .Book3ContentA-set-select {
    width: 20%;
  }

  .BookHeaderStatus-circle {
    margin: 0 0 0 0;
  }

  .form-search .Filter-confont-labelform {
    font-size: 13px;
  }
}

@media (max-width: 341px) {
  .BookContentA-span-option {
    font-size: 8px;
  }

  .BookContentA-space-mobile {
    width: 23%;
  }

  .BookContentA-width-logo {
    width: 22%;
  }

  .BookContentA-width-radio {
    width: 38%;
  }

  .BookContentA-pad-trans,
  .BookContentA-width-air {
    width: 37%;
  }

  .BookContentA-logo-air {
    margin-left: 2px;
  }

  .BookingStep3-con-span {
    font-size: 10px;
  }
}

@media (max-width: 332px) {
  .page-section
    .main-slider
    .form-search
    .row-inputs
    .container-fluid
    .Filter-repadr-input.Filter-conw-inputcode {
    width: 135px;
  }
}

@media (max-width: 320px) {
  .HomeFilter-conreactpicdate .react-datepicker-popper {
    left: -24px !important;
  }

  .page-section
    .main-slider
    .form-search
    .row-inputs
    .container-fluid
    .Filter-repadr-input.Filter-conw-inputcode {
    width: 50%;
  }
}

@media (max-width: 319px) {
  .page-section
    .main-slider
    .form-search
    .row-inputs
    .container-fluid
    .Filter-repadr-input.Filter-conw-inputcode {
    width: 135px;
  }
}

@media (max-width: 310px) {
  .BookContentA-con-btna,
  .BookContentA-con-btnb {
    width: 110px;
  }
}

@media (max-width: 1200px) {
  /* .wrapper .header-wrapper .navigation {
        display: none;
    } */
  .info-header .set-pad-info {
    font-size: 21px;
  }

  .page-a .hr-left-rede {
    width: 20%;
  }

  .page-a .related-title-rede {
    width: 60%;
  }

  .page-a .hr-right-rede {
    width: 20%;
  }
}

@media (min-width: 780px) {
  .dark .social-icons li {
    margin-right: 25px;
    height: 62px;
  }

  .dark .social-icons img {
    width: 187%;
  }

  .dark .social-icons a {
    margin-left: 0;
  }
}

@media (min-width: 992px) {
  .wrapper .sticky-wrapper.is-sticky .navigation .nav.sf-menu {
    line-height: 4;
  }

  .wrapper .is-sticky .navigation .nav.sf-menu .control-btn-login#myInput1 {
    top: 7px;
  }

  .Header-setpadb-btnlog {
    padding-bottom: 20px;
  }

  .navigation.closed {
    display: block !important;
  }

  .Header-conw-subour {
    width: 170px;
    margin-left: -30px !important;
  }

  .Header-conw-subour li {
    padding: 6px 10px;
    color: #a5abb7;
  }

  .Header-conw-subour li span .Header-borderbothover {
    border-bottom: 5px solid #fff;
  }

  .Header-conw-subour li span .Header-borderbothover:hover,
  .Header-conw-subour li span .Header-borderbothover:focus {
    border-bottom: 5px solid #01adef;
  }

  .Header-conw-subour a:focus span .Header-borderbothover {
    border-bottom: 5px solid #01adef;
  }

  .Header-conw-subour.Header-conw-mosubcom {
    width: 190px;
    margin-left: -30px !important;
  }

  .Header-conw-subour.Header-conw-mosubcon {
    width: 150px;
    margin-left: 0px !important;
  }

  .is-sticky .navigation {
    padding-top: 8px;
  }

  .Header-conw-subour.Header-conw-mosubloged {
    width: 130px;
    margin-left: -11px !important;
    box-shadow: none;
  }

  .is-sticky .Header-conw-subour {
    padding-top: 4px;
  }

  .is-sticky .Header-conw-subour.Header-conw-mosubloged {
    padding-top: 0px;
  }

  .is-sticky .Header-conw-subour.Header-conw-mosublagu {
    padding-top: 8px;
  }

  .wrapper
    .sticky-wrapper.is-sticky
    .navigation
    .nav.sf-menu
    .Header-conw-subour {
    line-height: 3;
  }

  .Header-conw-subour.Header-conw-mosublagu {
    text-align: center;
    min-width: unset;
    width: 45px;
    padding-top: 20px;
    box-shadow: none !important;
    margin-left: -6px !important;
  }

  .header-wrapper .sf-menu.nav .Header-conwpad-social {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 25px;
    transition: 0.3s;
  }

  .is-sticky .logo.control-phone-header {
    margin-left: 125px;
  }

  .logo {
    line-height: 75px;
  }

  .is-sticky .logo {
    line-height: 75px;
  }

  .is-sticky .logo img {
    height: 45px;
  }

  .wrapper .is-sticky .navigation .sf-menu .control-btn-login {
    margin-top: 0px;
  }

  .is-sticky .header-wrapper .sf-menu.nav .Header-conwpad-social {
    margin-top: 5px;
  }
}

/* Animate Text Header Content */
.container-title {
  animation: 1.5s ease-out 0s 1 slideInFromBottom;
}

@keyframes slideInFromBottom {
  0% {
    opacity: 0;
    transform: translateY(230%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Animate HomeFilter */
.HomeFilter-conanimate {
  animation: 1s ease-out 0s slideInFromBottom;

  transition: all 3s;
}

.HomeFilter-conanimate-opa {
  animation: 1.2s linear 0s opacityHomeFilter;
}

@keyframes opacityHomeFilter {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.thumbnail.HomeFilter-transscale:hover {
  transform: scale(1.16);
}

.image-hover > button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.thumbnail.HomeFilter-transscale:hover .btn-theme-transparent {
  background-color: #fff !important;
  color: #01adef;
  /* border-color: #01adef; */
}

.HomeFilter-conanimatetxt-our {
  animation: 1s ease-out 0s slideInFromLeft;
}

@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-160%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.thumbnail.wrap-promotions {
  height: 280px;
  overflow: hidden;
}

#book-now-mobile .rdtPicker {
  left: -120px !important;
}
