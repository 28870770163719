.Home-testimonial-box {
  border-radius: 32px;
  padding: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  min-width: 350px;
  min-height: 250px;
}

.Home-testimonial-box .description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Home-testimonial-box .profile-pic {
  width: 60px !important;
  height: 60px !important;
  border-radius: 50%;
  object-fit: cover;
}

.Home-testimonial-box .name {
  color: "#283746" !important;
  font-weight: 600;
}

.Home-testimonial-box .position {
  color: "#85A3D6" !important;
  font-weight: 500;
}

.rating {
  display: flex;
  align-items: center;
}

.rating-input {
  position: absolute !important;
  left: -9999px !important;
}

.rating-input:disabled {
  display: none;
}

.rating-label {
  padding: 0;
  margin: 0;
}

.rating-label > i,
.rating-label > .svg-icon {
  line-height: 1;
  color: yellow;
}

label.rating-label {
  cursor: pointer;
}

/* div.rating-label.checked,
label.rating-label {
  & > i,
  & > .svg-icon {
    color: var(--#{$prefix}rating-color-active);
  }
} */

div.rating-label.checked,
label.rating-label > i,
label.rating-label > .svg-icon {
  color: yellow;
}

/* .rating-input:checked ~ .rating-label {
  & > i,
  & > .svg-icon {
    color: var(--#{$prefix}rating-color-default);
  }
} */

.rating-input:checked ~ .rating-label > i,
.rating-input:checked ~ .rating-label > .svg-icon {
  color: yellow;
}

.rating:hover label.rating-label > i,
.rating:hover label.rating-label > .svg-icon {
  color: yellow;
}

/* .rating:hover label.rating-label {
  & > i,
  & > .svg-icon {
    color: var(--#{$prefix}rating-color-active);
  }
} */

/* label.rating-label:hover ~ .rating-label {
  & > i,
  & > .svg-icon {
    color: var(--#{$prefix}rating-color-default);
  }
  color: var(--#{$prefix}rating-color-default);
} */

label.rating-label:hover ~ .rating-label > i,
label.rating-label:hover ~ .rating-label > .svg-icon {
  color: yellow;
}
