.main-slider .item.FAQContentA-conbg-head {
  height: 350px;
  background-position: center;
}
.container-title .FAQContentA-conhr-head {
  min-width: 90px;
}
.FAQContentB-setmar-default .FAQContentB-addpad-setinfo {
  padding: 0 0 0 60px;
}
.FAQContentA-setbg-opacity {
  background: #055cab;
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.5;
}
.FAQContentA-contain-default {
  text-align: center;
  margin-top: 135px;
  position: relative;
}
.container-title .hr-promo {
  background: #fff;
  height: 7px;
  display: inline-block;
  margin-left: -2px;
}
.FAQContentB-setmar-default {
  margin-top: 30px;
  padding-bottom: 30px;
}
.FAQContentB-contxtcen-incol {
  text-align: center;
  margin-left: 0;
  margin-right: 0;
}
.FAQContentB-conwimg-markpoint {
  width: 70px;
}
.FAQContentB-span-blue {
  color: #01adef;
}
.FAQContentB-span-gray {
  color: #58595b;
}
.FAQContentB-span-red {
  color: #ee4231;
}
.FAQContentB-confont-head {
  font-size: 25px;
}
.FAQContentB-setmart-head {
  margin-top: 15px;
}
.FAQContentB-setmarb-head {
  margin-bottom: 60px;
}
.FAQContentB-borderl-row {
  border-left: 1px solid #999;
  border-right: 1px solid #999;
}
.FAQContentB-testborder-row {
  border-left: 1px solid #999;
  border-right: 1px solid #999;
}
.FAQContentB-border-right {
  border-right: 1px solid #999;
}
.FAQContentB-setpadl-east {
  padding-left: 50px;
}

.FAQContentB-remarlr-info {
  margin-right: 30px;
  margin-left: 30px;
}
.FAQContentB-setpadl-regionh {
  padding-left: 15px;
  color: #ee4231;
}
.FAQContentB-confont-loca {
  color: #58595b;
  font-size: 14px;
  padding-right: 0;
}
.FAQContentB-mocol1 {
  padding-left: 20px;
}
.FAQContentB-mocol2 {
  padding-left: 27px;
}
.FAQContentB-mocol3 {
  padding-left: 0;
  width: 31.333333%;
}
.FAQContentB-mocol4 {
  width: 23%;
  padding-left: 48px;
}
.FAQContentB-mocol5 {
  width: 32.333333%;
  padding-left: 45px;
}
.FAQContentB-imgw-default {
  width: 100%;
  height: 380px;
}
.FAQContentB-setpad-infosider {
  padding: 90px 40px 20px 40px;
}
.FAQContentB-conlogo-carhome {
  width: 60px;
}
.FAQContentB-set-marb-carhome {
  margin-bottom: 15px;
}
.FAQContentB-confont-infocarhome {
  color: #01adef;
  font-size: 20px;
  margin-bottom: 15px;
}
.FAQContentB-confont-detailcarhome {
  font-size: 14px;
  margin-bottom: 15px;
}
.FAQContentB-setup-redbtn {
  border-radius: 8px;
  padding: 12px 20px;
}
.FAQContentB-conpolygon-carhome {
  width: 5px;
  margin-left: 15px;
  padding-bottom: 2px;
}

.FAQContentB-setpad-carprice-sidel {
  padding: 0 30px;
}
.FAQContentB-setfloat-carprice {
  float: left;
  margin-top: -15px;
  margin-right: 15px;
}
.FAQContentB-conw-logocarprice {
  width: 70px;
}
.FAQContentB-conspan-headcarprice {
  color: #01adef;
  font-size: 16px;
  margin-bottom: 50px;
}
.FAQContentB-setfloat-infocarprice {
  float: left;
  margin-right: 20px;
}
.FAQContentB-confont-infocarprice {
  font-size: 14px;
}
.FAQContentB-conwlogo-carprice {
  width: 40px;
}
.FAQContentB-setmarb-infocarprice {
  margin-bottom: 35px;
}
.FAQContentB-setmarb-infocarpricea {
  padding-top: 24px;
  margin-top: -34px;
}
.FAQContentB-setpadt-mo {
  padding-top: 0px;
  margin-bottom: 25px;
}
.FAQContentB-setmart-infocarpricemo {
  margin-top: 8px;
}
.FAQContentB-setpad-keysider {
  padding-left: 70px;
}
.row .col-md-5.FAQContentB-setmar-carprice {
  margin-top: 60px;
}
.row .col-md-7.FAQContentB-remart-keysider {
  margin-top: 0;
}
.FAQContentB-settxtcen-doc {
  text-align: center;
}
.FAQContentB-conwimg-headdoc {
  width: 70%;
}
.FAQContentB-conspande-doc {
  font-size: 13px;
}
.FAQContentB-repadrl-fortxt {
  padding-left: 0;
  padding-right: 0;
}
.FAQContentB-conspanh-doc {
  font-size: 16px;
  color: #01adef;
}
.FAQContentB-conwlogo-doc {
  width: 80px;
}

.FAQContentA-setbg-opacity-footer {
  background: #055cab;
  position: absolute;
  height: 100%;
  width: 50%;
  opacity: 0.5;
}
.FAQContentC-setcentxt-onbg {
  color: #fff;
  padding: 100px 80px;
}
.FAQContentC-confont-sldw {
  color: #fff;
  font-size: 16px;
  margin-bottom: 30px;
}
.FAQContentB-conspan-de-sldw {
  font-size: 14px;
}
.FAQContentD-container-mo {
  margin: 30px 100px;
}
.FAQContentD-confont-footer {
  font-size: 14px;
  color: #fff;
  margin-bottom: 15px;
}
.FAQContentD-setcen-footer {
  text-align: center;
  margin-bottom: 25px;
}
.FAQContentB-hr-mobile {
  display: none;
  height: 1px;
  background: #999;
  margin-top: 40px;
}
.FAQContentB-congooglemap {
  width: 480px;
  height: 380px;
  border: 0;
}
.FAQContentB-setpadl-regionh {
  font-size: 17px;
}

@media (min-width: 993px) {
  .FAQContentB-imgwnoh-default {
    width: 120%;
    margin-left: -140px;
  }
  .container .row.FAQContentB-mart-fpc {
    margin-top: 20px;
  }
}

@media (max-width: 1200px) {
  .FAQContentB-congooglemap {
    width: 365px;
    height: 370px;
  }
  .FAQContentB-mocol4 {
    width: 23%;
    padding-left: 25px;
  }
  .FAQContentB-mocol2 {
    width: 38.333333%;
    padding-left: 5px;
  }
  .FAQContentB-mocol1 {
    padding-left: 0;
  }
  .FAQContentB-mocol5 {
    width: 34.333333%;
  }
  .FAQContentC-setcentxt-onbg {
    padding: 100px 45px;
  }
  .FAQContentB-setmart-infocarpricemo {
    margin-top: 16px;
    margin-bottom: 20px;
  }
  .FAQContentB-setmar-default .FAQContentB-addpad-setinfo {
    padding: 0 0 0 25px;
  }
  .FAQContentB-setpadl-east {
    padding-left: 29px;
    padding-right: 0;
  }
  .FAQContentB-repad-ipadpro,
  .FAQContentB-repad-ipadsouth {
    padding: 0;
  }
}

@media (max-width: 992px) {
  .FAQContentB-congooglemap {
    width: 100%;
    height: 380px;
  }
  .FAQContentB-imgwnoh-default {
    width: 100%;
    margin-left: -90px;
  }
  .FAQContentD-container-mo {
    margin: 30px 15px;
  }
  .FAQContentB-mocol1 {
    padding-left: 15px;
    width: 100%;
  }
  .FAQContentB-mocol2 {
    padding-left: 15px;
    border-left: unset;
    width: 100%;
  }
  .FAQContentB-control-mobile {
    display: none;
  }
  .row .col-md-4.FAQContentB-mocol3 {
    margin-top: 30px;
    padding-left: 15px;
    width: 100%;
  }
  .FAQContentB-mocol4 {
    border-left: unset;
    border-right: unset;
    padding-left: 15px;
    width: 100%;
  }
  .FAQContentB-mocol5 {
    padding-left: 15px;
    width: 100%;
  }
  .FAQContentB-setmarb-head {
    margin-bottom: 0;
  }
  .FAQContentB-setmarb-mobile {
    margin-bottom: 30px;
  }
  .FAQContentB-conwimg-headdoc {
    width: 80%;
    margin-top: 80px;
  }
  .FAQContentB-setup-redbtn {
    width: 80%;
  }
  .FAQContentB-conspan-de-sldw {
    font-size: 14px;
  }
  .FAQContentB-hr-mobile {
    display: block;
    margin-left: 30px;
    margin-right: 30px;
  }
  .FAQContentB-repad-infocarprice-b {
    padding-top: 10px;
  }
  .FAQContentB-repad-infocarprice-c {
    padding-top: 18px;
  }
  .FAQContentB-setmar-default .FAQContentB-addpad-setinfo {
    padding: 0 15px;
  }
  .FAQContentB-setpadl-east {
    padding-left: 15px;
  }
  .FAQContentB-border-right {
    border-right: 1px solid #fff;
  }
  .FAQContentB-testborder-row {
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
  }
  .FAQContentC-setcentxt-onbg {
    padding: 100px 45px;
  }
  .FAQContentB-setpad-infosider {
    padding: 0 40px 20px 40px;
  }
  .FAQContentB-repad-ipadpro,
  .FAQContentB-repad-ipadsouth {
    padding: 0 15px;
  }
  .FAQContentB-hr-mobileop1 {
    height: 0.5px;
  }
}

@media (max-width: 768px) {
  .FAQContentB-setpad-infosider {
    padding: 0 0 20px 0;
  }
  .FAQContentB-setpad-carprice-sidel {
    padding: 0 0;
  }
  .FAQContentB-setpad-keysider {
    padding-left: 0px;
    margin-left: -60px;
  }
  .FAQContentB-conwimg-headdoc {
    width: 100%;
  }
  .FAQContentB-conspanh-doc {
    font-size: 18px;
  }
  .FAQContentB-setup-redbtn {
    width: 60%;
  }
  .FAQContentA-setbg-opacity-footer {
    width: 100%;
  }
  .FAQContentB-remarlr-info {
    margin-right: 0;
    margin-left: 0;
  }
  .FAQContentB-repadlr-mo {
    padding-right: 0;
    padding-left: 0;
    margin-left: -15px;
    margin-right: -15px;
  }

  .FAQContentB-imgw-default {
    height: unset;
  }
  .FAQContentB-conlogo-carhome {
    width: 80px;
  }
}
@media (max-width: 550px) {
  .FAQContentD-confont-footer {
    font-size: 10px;
  }
  .FAQContentB-setup-redbtn {
    width: 90%;
  }
  .FAQContentB-imgwnoh-default {
    margin-left: -60px;
  }
  .FAQContentB-repadt-infocarprice-b {
    padding-top: 10px;
  }
  .FAQContentB-repadt-infocarprice-c {
    padding-top: 11px;
  }
}

@media (max-width: 500px) {
  .FAQContentB-setmar-default {
    padding-bottom: 30px;
  }
}

@media (max-width: 420px) {
  .FAQContentC-setcentxt-onbg {
    padding: 80px 30px;
  }
}

@media (max-width: 380px) {
  .FAQContentC-setcentxt-onbg {
    padding: 60px 30px;
  }
  .FAQContentB-hr-mobile {
    height: 0.5px;
  }
  .FAQContentB-hr-mobile.FAQContentB-hr-mobileop {
    height: 1px;
  }
  .FAQContentB-hr-mobileop1 {
    height: 1px;
  }
}

@media (max-width: 350px) {
  .FAQContentD-confont-footer {
    font-size: 10px;
  }
  .FAQContentB-setup-redbtn {
    width: 100%;
  }
  .FAQContentB-imgwnoh-default {
    margin-left: -50px;
  }
}

@media (max-width: 340px) {
  .FAQContentB-setmart-infocarpricemo {
    margin-top: 25px;
    margin-bottom: 20px;
  }
}

@media (max-width: 330px) {
  .FAQContentC-setcentxt-onbg {
    padding: 55px 30px;
  }
}

/* Animate Car Type */
.FAQContentB-setpad-infosider {
  opacity: 0;
}
.FAQContentB-conanimate-cartype {
  opacity: 1;
  animation: 1.5s ease-out 0s 1 slideInFromRight;
}
@keyframes slideInFromRight {
  0% {
    opacity: 0;
    transform: translateX(240%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Animate Car Price */
.FAQContentB-setpad-carprice-sidel {
  opacity: 0;
}
.FAQContentB-remarlr-info .FAQContentB-conanimate-carprice {
  opacity: 1;
  animation: 1.5s ease-out 0s 1 slideInFromLeft;
}
@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-240%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Animate Car Doc */
.FAQContentB-hideforanimate {
  opacity: 0;
}
.FAQContentB-settxtcen-doc .FAQContentB-conanimate-cardoc {
  opacity: 1;
  animation: 1.5s ease-out 0s 1 slideInFromBottom;
}
@keyframes slideInFromBottom {
  0% {
    opacity: 0;
    transform: translateY(300px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Animate Car Sldw */
.FAQContentC-setcentxt-onbg {
  opacity: 0;
}
.FAQContentB-conanimate-carsldw {
  opacity: 1;
  animation: 1.5s ease-out 0s 1 slideInFromLeft;
}

.row-branchs > div {
  margin-top: 10px !important;
}
