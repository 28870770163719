
.BookingStep4-rowmar-default {
  margin: 0 60px 30px 60px;
}
.BookHeaderStatus-row {
  margin: 35px 0;
  background: #01adef;
  height: 150px;
}
.BookingStep4-span-blueS {
  color: #055cab;
}
.BookingStep4-span-blue {
  color: #01adef;
  font-weight: 600;
}
.BookingStep4-font-default {
  font-size: 14px;
}
.BookingStep4-span-gray {
  color: #818285;
}
.Book4ContentA-set-select {
  border-color: #bce0fd;
  color: #01adef;
  font-size: 12px;
  background: #fff;
  padding: 8px;
  width: 100%;
}
.BookingStep4-span-red {
  color: #ee4231;
}
.BookingStep4-maringroup {
  margin-bottom: 35px;
}
.BookingStep4-conwidth-input,
.BookingStep4-conwidth-inputP {
  border: 1px solid #01adef;
  border-radius: 3px;
  height: 40px;
  padding-left: 5px;
  color: #01adef;
}
.BookingStep4-conwidth-input.danger,
.BookingStep4-conwidth-inputP.danger {
  border:  1px solid #F00;
}

.BookingStep4-conwidth-input:read-only,.BookingStep4-conwidth-inputP:read-only {
  background-color: #E9E9E9;
}


.BookingStep4-conwidth-input {
  width: 50%;
}
.BookingStep4-conwidth-inputP {
  width: 40%;
}
.Book4ContentA-marb-span {
  margin-bottom: 5px;
}
.BookingStep4-setbg-info {
  background: #fff;
}
.Book4ContentA-con-btna,
.Book4ContentA-con-btnb {
  color: #fff;
  padding: 11px;
  font-size: 13px;
  margin-right: 5px;
  border-radius: 5px;
  width: 150px;
}
.Book4ContentA-con-btna {
  background: #01adef;
  border: 1px solid #01adef;
}
.Book4ContentA-con-btnb {
  background: #ee4231;
  border: 1px solid #ee4231;
}
.Book3ContentA-head-option {
  font-size: 20px;
}
.glyphicon-ok {
  color: #01adef;
}

/* Control Checkbox */
.checkbox .cr {
  border: 1px solid #055cab;
  border-radius: 0;
}

.radio .cr {
  left: -20px;
}
.btnCheckEmail {
 margin: 0 0 0 10px;
}
@media (max-width: 992px) {
  .Book4ContentA-btnfoot-content {
    margin-left: 0;
    margin-top: 15px;
  }
  .Book3ContentA-head-option {
    margin-right: 0;
  }
  .BookingStep4-setbg-info {
    margin-left: 15px;
    margin-right: 15px;
  }
  .BookingStep4-marinfo-mo {
    margin-left: 0;
    margin-right: 0;
  }
  .Book4ContentA-remar-btnfoot {
    margin-bottom: 30px;
  }
  .BookHeaderStatus-row {
    margin: 0 0 35px 0px;
  }
}
@media (max-width: 768px) {
  
  .Book4ContentA-btnfoot-content {
    text-align: center;
  }
  .BookingStep4-conwidth-input {
    width: 100%;
  }
  .BookingStep4-conwidth-inputP {
    width: 80%;
  }
  .BookingStep4-setbg-info {
    margin-left: 0;
    margin-right: 0;
  }
  .Book3ContentA-head-option {
    display: block;
  }
  .BookingStep4-font-default {
    font-size: 13px;
  }
  .BookingStep4-rowmar-default {
    margin: 0 15px 30px 15px;
  }
  .Book4ContentA-con-btna,
  .Book4ContentA-con-btnb {
    width: 40%;
  }
  .btnCheckEmail {
   margin: 10px 0 0 0;
  }
}

@media (max-width: 500px) {
  .line2_smartpay {
    display: block;
    padding-left: 25px;
  }
  .Book3ContentA-head-option {
    font-size: 18px;
  }
  .BookingStep4-font-default {
    font-size: 13px;
  }
}

@media (max-width: 400px) {
  .BookingStep4-font-default {
    font-size: 12px;
  }
}
