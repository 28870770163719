.service-comparison-table {
  font-family: "Prompt";
  width: 100%;
  margin: 20px auto;
  border: 1px solid #e2e8f0;
}

.service-comparison-table > * {
  padding: 20px 30px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 70px;
}

.service-comparison-table > *:nth-child(odd) {
  background-color: #edf6fd;
}

/* *Header */

.service-comparison-table .label-header {
  width: 40%;
  color: #3a78c9;

  text-align: center;
  font-family: Prompt;
  font-size: 27px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.service-comparison-table .info-header {
  width: 20%;
  text-align: center;
}

.service-comparison-table .info-header .title {
  display: block;
  color: #263644;

  text-align: center;
  font-family: Prompt;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}

.service-comparison-table .info-header .description {
  display: block;
  color: #5c5c5a;

  text-align: center;
  font-family: Prompt;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

/* *Contents */

.service-comparison-table .label-item {
  width: 40%;
  color: #263644;
  text-align: start;

  font-family: Prompt;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.service-comparison-table .info-item {
  text-align: center;
  width: 20%;
}

.service-comparison-table .info-item img {
  width: 40px;
  height: 40px;
}

@media (max-width: 768px) {
  .service-comparison-table .label-header {
    font-size: 16px;
  }

  .service-comparison-table .info-header .title {
    font-size: 14px;
  }

  .service-comparison-table .info-header .description,
  .service-comparison-table .label-item,
  .service-comparison-table .info-item {
    font-size: 12px;
  }

  .service-comparison-table .info-item img {
    width: 20px;
    height: 20px;
  }
}
