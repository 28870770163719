.ForgotModal-rebuild-btn-modal-sub {
    width: 100%;
    border-radius: 5px;
    padding: 10px 0;
}
.ForgotModal-setpadmar-default {
    padding: 0 115px;
    margin-bottom: 30px;
}
body.modal-open {
    overflow: hidden;
    padding-right: 0px !important;
}

@media (max-width: 610px) {
    .ForgotModal-setpadmar-default {
        padding: 0 0;
    }
}
