.Promotion-Detail-fluid {
  position: relative;
  width: 80vw;
}

@media (max-width: 768px) {
  .Promotion-Detail-fluid {
    width: 90vw !important;
    padding-right: 0;
    padding-left: 0;
  }

  .control-detail-related a {
    padding: 4px 30px;
    font-size: 1.2rem;
  }
}
