.owl-theme .owl-dots {
  width: 100%;
  position: absolute;
  bottom: 20px;
  left: 0;
  text-align: center;
}

#promotion-section.owl-theme .owl-dots,
#partner-section.owl-theme .owl-dots,
#journey-section.owl-theme .owl-dots,
#testimonials-section.owl-theme .owl-dots {
  bottom: -40px;
}

#testimonials-section.owl-theme .owl-stage-outer {
  padding: 5px;
  padding-right: 40px;
}

#testimonials-section.owl-theme:last-child .owl-item.active {
  margin-right: 30px !important;
}

.main-booking-form {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}
.owl-nav.disabled {
  display: none;
}
.iframe_banner {
  min-height: 530px;
}
.main-booking-form .return-label-desktop,
.main-booking-form .submit-button-desktop {
  display: block;
}
.main-booking-form .return-label-mobile,
.main-booking-form .submit-button-mobile {
  display: none;
}
@media (max-width: 767px) {
  .page-section {
    background-color: #fff;
  }
  .wrapper .content-area .page-section.sec-a {
    padding-top: 20px;
  }
  .main-booking-form {
    position: relative;
    /* margin-top: 25px; */
    transform: translate(0, 0);
    left: 0;
    width: auto;
  }
  .main-booking-form .return-label-desktop,
  .main-booking-form .submit-button-desktop {
    display: none;
  }
  .main-booking-form .return-label-mobile,
  .main-booking-form .submit-button-mobile {
    display: block;
  }
  #home-banner {
    display: none;
  }
  .div-table,
  .div-cell {
    display: block !important;
  }
  .horizontal-scroll {
    overflow: auto;
    overflow-x: scroll;
    white-space: nowrap;
  }

  .horizontal-scroll > * {
    display: inline-block;
  }

  .Home-fluid {
    width: 90vw !important;
    padding-right: 0;
    padding-left: 0;
  }

  .section-header {
    font-size: 24px !important;
    margin-bottom: 20px !important;
  }

  .section-seemore {
    right: 0 !important;
    font-size: 12px;
  }

  .Home-close-video-popup-button {
    top: -20px !important;
    right: -10px !important;
  }
}

.main-booking-form .form-search .reset-group {
  float: unset;
}
.main-booking-form .container {
  width: auto;
  background-color: #f8f7f9;
  border-radius: 30px;
}
.main-booking-form .container .div-table {
  width: 80vw;
  margin: 0 auto;
  background-color: #f8f7f9;
  border-radius: 30px;
}

.owl-carousel .item-bg {
  height: 320px;
  background-position: center center;
  background-size: cover;
}

.calendar-index {
  z-index: 100;
}

.section-header {
  color: #223645;
  font-family: Prompt;
  text-align: center;
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 50px;
}

.section-seemore {
  position: absolute;
  top: 20px;
  right: 60px;
  text-transform: uppercase;
  cursor: pointer;
  color: #58595b;
  font-family: Prompt;
  transition: all 300ms;
  text-decoration: underline;
}

.section-seemore:hover {
  color: #223645;
  text-decoration: underline;
}

.Home-fluid {
  position: relative;
  width: 80vw;
}

.Home-service-box {
  background-color: transparent;

  margin-bottom: 10px;
  cursor: pointer;
  height: 250px;
  width: 350px;
  color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.Home-service-box .bg-img {
  box-shadow: inset 0 0 0 2000px rgba(5, 92, 171, 0.5);
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.Home-service-box .bg-img::before {
  content: "";
  display: none;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(52, 73, 94, 0.6);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.Home-service-box:hover .bg-img:before,
.Home-service-box:focus .bg-img:before {
  display: block;
}

.Home-service-box:hover .bg-img,
.Home-service-box:focus .bg-img {
  transform: scale(1.1);
}

.Home-service-box button {
  text-align: center;
  width: 60%;
  height: 30px;
  font-family: Prompt;
  border: 2px solid #fff;
  opacity: 0.9984;
  background: rgba(255, 255, 255, 0.2);
  font-weight: 700;
  font-size: 16px;

  position: absolute;
  z-index: 1;
}

.Home-new-box {
  overflow: hidden;
  transition: all 300ms;
}

.Home-new-box img {
  transition: all 300ms;
}

.Home-new-box:hover img,
.Home-new-box:focus img {
  transform: scale(1.1);
}

.Home-close-video-popup-button {
  position: absolute;
  top: 5px;
  right: -60px;
  z-index: 100;
  color: #fff;
  font-size: 30px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  transition: all 300ms;

  display: flex;
  align-items: center;
  justify-content: center;
}

.Home-close-video-popup-button:hover {
  opacity: 0.6;
}

.Home-testimonials-title {
  color: #263644;
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
}

.Home-testimonials-subtitle {
  color: #5483cb;
  text-align: center;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
}

.Home-testimonials-text {
  color: #5c5c5a;
  text-align: center;
  width: 70%;
  margin: 0 auto;
}
