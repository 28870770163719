.Journey-Recent-Post-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.Journey-Recent-Post-item-container .img-container {
  overflow: hidden;
  width: 40%;
  border-radius: 8px;
}

.Journey-Recent-Post-item-container .img-container img {
  height: 100px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.Journey-Recent-Post-item-container .content-container {
  padding: 20px;
  width: 60%;
}

.Journey-Recent-Post-item-container .content-container .content-title {
  color: #0193cd;
  font-weight: bold;
  cursor: pointer;
}

.Journey-Recent-Post-item-container .content-container .content-title:hover {
  color: #0193cd;
  text-decoration: underline;
}

.Journey-Recent-Post-item-container .img-container img:hover {
  border-radius: 8px;
  opacity: 0.6;
}
