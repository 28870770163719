.Journey-sidebar-hr {
  width: 100%;
  height: 1px;
  background-color: #838383;
  border-color: #838383;
}

.Journey-sidebar-header {
  color: #000;
  font-size: 1.5rem;
  font-weight: bold;
}

.Journey-sidebar-category-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  row-gap: 10px;
}

.Journey-sidebar-category-container .item {
  width: 100% !important;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  column-gap: 10px;

  cursor: pointer;

  transition: all 0.1s ease-in-out;
}

.Journey-sidebar-category-container .item:hover {
  color: #149cd2;
}
