.partner-card-container {
  background-color: rgb(255, 255, 255);
  color: #055cab;
  padding-bottom: 40px;
}

.partner-card-container .content {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  height: 140px;
}

.partner-card-container .content .partner-card-header {
  margin-bottom: 12px !important;
  width: 75%;
  margin: 0 auto;
  padding-top: 20px;

  min-height: 50px;

  font-weight: bold;
}

.partner-card-container .content .partner-card-description {
  width: 75%;
  margin: 0 auto;
  font-size: 14px;
}

.partner-card-container .img-container {
  height: 350px;
  overflow-y: hidden;
}

@media (max-width: 1599px) {
  .partner-card-container .img-container {
    height: 275px;
  }
}

.partner-card-container img {
  width: 100%;
  object-fit: cover;
}

.partner-card-container .partner-card-button {
  background-color: #01adef;
  color: #ffffff;
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 600;
  outline: none;
  border: 1px solid transparent;

  transition: all 300ms;
  width: fit-content;
}

.partner-card-container .partner-card-button:hover {
  color: #0193cd;
  border-color: #0193cd;
  background-color: #ffffff;
}

.PartnerContentA-setbg-opacity-footer {
  background: #055cab;
  position: absolute;
  height: 100%;
  width: 50%;
  opacity: 0.5;
}
.main-slider .item.PartnerContentA-conbg-head {
  height: 350px;
  background-position: center;
}
.PartnerContentA-contain-default {
  text-align: center;
  margin-top: 135px;
  position: relative;
}
.container-title .PartnerContentA-conhr-head {
  min-width: 90px;
}
.container-title .PartnerContentA-hr-promo {
  background: #fff;
  height: 7px;
  display: inline-block;
  margin-left: -2px;
}
.PartnerContentA-setbg-opacity {
  background: #055cab;
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.5;
}
.PartnerContentB-setcon-default {
  margin: 45px 120px;
}
.PartnerContentB-settxtcen {
  text-align: center;
}
.PartnerContentB-conwlogo {
  width: 80%;
}

.PartnerContentC-setbg-opacity {
  background: #055cab;
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.5;
}

.main-slider .item.PartnerContentC-conbg-head {
  height: 350px;
  background-position: center;
}
.PartnerContentC-contain-default {
  text-align: center;
  margin-top: 85px;
  position: relative;
}
.PartnerContentC-conbtn-image {
  margin-top: 40px;
}
.container-title .PartnerContentC-conhr-head {
  min-width: 90px;
}
.container-title .PartnerContentC-hr-promo {
  background: #fff;
  height: 7px;
  display: inline-block;
  margin-left: -2px;
}
.PartnerContentC-conimg-button {
  width: 50%;
}
.PartnerContentC-buildbtn-img {
  width: 25%;
  /* background: #fff;
    border: 10px solid #fff; */
  padding: 0;
}

@media (min-width: 992px) {
  .PartnerContentB-setpadfor-pc {
    padding: 33px 155px;
  }
}

@media (max-width: 992px) {
  .PartnerContentB-setcon-default {
    margin: 45px 0;
  }
  .PartnerContentC-remar-confmo {
    width: unset;
  }
  .PartnerContentB-conwlogo {
    width: 80%;
  }
  .PartnerContentB-conwimage-a,
  .PartnerContentB-conwimage-b,
  .PartnerContentB-conwimage-c,
  .PartnerContentB-conwimage-d,
  .PartnerContentB-conwimage-e,
  .PartnerContentB-conwimage-f,
  .PartnerContentB-conwimage-g {
    width: 50%;
    float: left;
  }
  .main-slider .item .container-title.PartnerContentC-contain-default strong {
    font-size: 60px;
  }
}

@media (max-width: 768px) {
  .PartnerContentC-buildbtn-img {
    width: 40%;
  }
  .PartnerContentC-setbg-opacity {
    opacity: 0;
  }
}

@media (max-width: 550px) {
  .PartnerContentC-buildbtn-img {
    width: 60%;
  }
  .main-slider .item .container-title.PartnerContentC-contain-default strong {
    font-size: 55px;
  }
}

@media (max-width: 450px) {
  .PartnerContentC-buildbtn-img {
    width: 80%;
  }
  .main-slider .item .container-title.PartnerContentC-contain-default strong {
    font-size: 50px;
  }
}

@media (max-width: 350px) {
  .main-slider .item .container-title.PartnerContentA-contain-default strong {
    font-size: 43px;
  }
}
