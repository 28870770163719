.ddd {
	font-size:18px;
}
.Book1ContentA-setcontainer-default {
  padding: 30px 200px;
}
.Book1ContentA-setborder-form {
  border: 1px solid #999;
}
.Book1ContentA-setpad-infoform {
  padding: 0 70px 30px;
}
.form-search .Book1ContentA-setpadl-titleform {
  padding-left: 90px;
  background: #055cab;
}
.Book1ContentA-conmart-same .glyphicon.glyphicon-ok {
  color: #055cab;
}
.form-search .Book1ContentA-setpadl-titleform:after {
  display: none;
}
.Book1ContentA-setpad-infoform .form-group .form-control {
  height: 40px;
}
.form-search .Book1ContentA-confont-labelform {
  color: #01adef;
  font-size: 17px;
  font-weight: 505;
}
.form-search .form-title .Book1ContentA-confont-bookhead {
  font-size: 22px;
  font-weight: 500;
}
.Book1ContentA-conbtndanger-book {
  width: 40%;
}
.form-search .Book1ContentA-contopcheck {
  top: 32px;
}
.form-group .Book1ContentA-confont-input {
  font-size: 14px;
}

.form-search .Book1ContentA-confont-input::-webkit-input-placeholder {
  color: #bce0fd !important;
}
.form-search .form-control-icon.Book1ContentA-conbg-chk {
  background-color: unset;
  top: 30px;
  font-size: 25px;
}
.Book1ContentA-setpad-infoform
  .form-group
  .form-control.Book1ContentA-con-inputcode {
  font-size: 14px;
}
.Book1ContentA-setpad-infoform
  .form-group
  .form-control.Book1ContentA-con-inputcode::placeholder {
  color: #bce0fd !important;
}
.container-fluid .form-group .Book1ContentA-confont-same {
  font-size: 15px;
}
.Book1ContentA-confloatlabel-re {
  float: left;
}

.Book1ContentA-conmart-same {
  margin-top: -5px;
  padding-left: 90px;
  padding-top: 0px;
  margin-bottom: 0;
  top: 1px;
}
.Book1ContentA-confont-same {
  color: #a5abb7;
  font-size: 13px;
  padding-left: 5px;
}

@media (min-width: 1201px) {
}

@media (max-width: 1200px) {
  .form-group .Book1ContentA-confont-input {
    font-size: 13px;
  }
}

@media (max-width: 992px) {
  .Book1ContentA-setcontainer-default {
    padding: 30px 15px;
  }
  .form-search.light .form-title .Book1ContentA-confont-bookhead {
    text-align: center;
    max-width: unset;
  }
  .form-search .Book1ContentA-setpadl-titleform {
    padding-left: unset;
  }
  .Book1ContentA-setpad-infoform {
    padding: 0 0 30px;
  }
  /* .Book1ContentA-setmarb-mo {
        margin-bottom: 10px;
    } */
  /* .Book1ContentA-conw-select {
        width: 50%;
    } */
}
@media (max-width: 768px) {
  .Book1ContentA-conbtndanger-book {
    width: 60%;
  }
}

@media (max-width: 650px) {
  .Book1ContentA-conbtndanger-book {
    width: 80%;
  }
}

@media (max-width: 460px) {
  .Book1ContentA-conbtndanger-book {
    width: 100%;
  }
}

@media (max-width: 420px) {
  .Book1ContentA-conw-select {
    width: 90px;
  }
  .form-search .Book1ContentA-confont-labelform {
    font-size: 16px;
  }
  .container-fluid .form-group .Book1ContentA-confont-same {
    font-size: 13px;
  }
}

@media (max-width: 350px) {
  .Book1ContentA-confloatlabel-re {
    float: unset;
  }
  .Book1ContentA-conmart-same {
    padding-left: 20px;
    padding-bottom: 5px;
  }
}
