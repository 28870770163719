.LoginModal-conbtn-closemodal {
    opacity: 1;
    font-size: 28px;
    color: #fff;
}
.LoginModal-conbtn-closemodal:hover {
    cursor: pointer;
}
#myInput:hover {
    cursor: pointer;
}
.modal-txtheadcen {
    text-align: center;
    padding: 20px;
}
.LoginModal-title {
    font-size: 24px;
    font-weight: 600;
}
.LoginModal-con-border {
    border-color: #bce0fd;
}
.LoginModal-set-container {
    /* margin: 30px 100px; */
}
.LoginModal-con-inpute,
.LoginModal-con-inputp {
    margin: 10px 0;
    color: #2699fb;
}
.LoginModal-logo-email,
.LoginModal-logo-pass {
    background: #2699fb;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.LoginModal-logomail-w,
.LoginModal-logopass-w {
    width: 20px;
}
.LoginModal-con-forgotpass {
    text-align: right;
    font-size: 12px;
    color: #2699fb;
    padding-right: 10px;
}
.LoginModal-con-signin {
    width: 100%;
    text-align: center;
    padding: 17px 0;
    border-radius: 10px;
    font-size: 17px;
}
.LoginModal-setmar-signin {
    margin: 30px 0;
}
.LoginModal-hr-l,
.LoginModal-hr-r {
    height: 1px;
    width: 100%;
    background: #a8a9ac;
    margin-top: 10px;
}
.LoginModal-txt-hr {
    color: #a8a9ac;
    font-size: 18px;
}
.LoginModal-build-btnF {
    background: #055cab;
    border-radius: 10px;
    padding: 15px;
}
.LoginModal-setfloatab-logo {
    float: left;
    position: absolute;
}
.LoginModal-contxt-logF,
.LoginModal-contxt-logL {
    text-align: center;
    color: #fff;
    font-size: 17px;
}
.LoginModal-build-btnL {
    background: #3ace01;
    border-radius: 10px;
    padding: 15px;
}
.LoginModal-conw-logoF {
    width: 14px;
    margin-left: 6px;
}
.LoginModal-conw-logoL {
    width: 30px;
}
.LoginModal-con-regis {
    text-align: center;
    margin-top: 20px;
    font-size: 12px;
}

@media (max-width: 768px) {
    #myModal1 {
        padding-left: 0;
    }
}
@media (max-width: 600px) {
    .LoginModal-set-container {
        margin: 30px 0;
    }
}
