.OurService-car-left {
  position: relative;
  z-index: 1;
  padding-top: 70px;
  padding-bottom: 50px;
  height: 350px;
}

.OurService-car-left:before {
  position: absolute;
  background: #fff none repeat scroll 0 0;
  content: "";
  top: 0;
  right: 43%;
  width: 70px;
  height: 100%;
  -webkit-transform: skewX(45deg);
  transform: skewX(45deg);
  z-index: -1;
}

.OurService-car-left:after {
  position: absolute;
  background: #275ba5 none repeat scroll 0 0;
  content: "";
  top: 0;
  right: 55%;
  width: 2030px;
  height: 100%;
  -webkit-transform: skewX(45deg);
  transform: skewX(45deg);
  z-index: -1;
}

.OurService-car-right {
  padding-top: 72px;
}

.OurService-car-right h3 {
  font-size: 34px;
  color: #fff;
  letter-spacing: 1px;
  margin-bottom: 15px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  display: inline-block;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 50px;
}

.OurService-car-right .button {
  color: #fff !important;
  background: transparent;
  border-width: 2px;
  border-style: solid;
  border-color: #ec3323;
  position: relative;
  margin: 1em;
  display: block;
  padding: 8px 30px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: fit-content;
  cursor: pointer;
}

.OurService-car-right .button:before,
.OurService-car-right .button:after {
  content: "";
  display: block;
  position: absolute;
  border-color: #ec3323;
  box-sizing: border-box;
  border-style: solid;
  width: 1em;
  height: 1em;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.OurService-car-right .button:before {
  top: -6px;
  left: -6px;
  border-width: 2px 0 0 2px;
  z-index: 5;
}

.OurService-car-right .button:after {
  bottom: -6px;
  right: -6px;
  border-width: 0 2px 2px 0;
}

.OurService-car-right .button:hover:before,
.OurService-car-right .button:hover:after {
  width: calc(100% + 12px);
  height: calc(100% + 12px);
  border-color: #ec3323;
}

.OurService-car-right .button:hover {
  color: #fff;
  background-color: #ec3323;
  border-color: #ec3323;
}

.OurService-book-now-text {
  font-size: 36px;
  font-weight: bold;
  text-transform: uppercase;
  color: #263644;
}

.OurService-book-now-button {
  border-radius: 8px;
  background: #ec1e25;
  padding: 10px 30px;
  color: #ffffff;
  border-radius: 8px;
  outline: none;
  border: none;
  transition: all 0.3s ease-in-out;
  font-weight: bold;
}

.OurService-book-now-button:hover {
  background: #ab2519;
}

.section-header {
  color: #223645;
  font-family: Prompt;
  text-align: center;
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  text-transform: none;
  margin-bottom: 0px;
}

.why-title {
  color: #3a78c9;

  text-align: center;
  font-family: Prompt;
  font-size: 27px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.what .what-we-offer-title {
  color: #263644;
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
}

.what-we-offer-sub-title {
  color: #5c5c5a;
  text-align: center;
  width: 70%;
  margin: 0 auto;
}

.what-we-offer-button {
  border-radius: 4px;
  padding: 10px 30px;
  width: 100%;
  height: 350px;
  border: 1px solid #eaeff2;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;

  row-gap: 20px;
}

.what-we-offer-button img {
  width: 60px;
  margin: 0 auto;
}

.what-we-offer-button .title {
  color: #263644;
  font-weight: bold;
  font-size: 20px;
}

.what-we-offer-button p {
  color: #5c5c5a;
  font-size: 16px;
}

@keyframes shadowPulse {
  0% {
    box-shadow: 0 0 30px 10px #055cab;
    background-color: #1c90fa;
  }

  100% {
    box-shadow: none;
    background-color: #055cab;
  }
}

.shadow_blue {
  animation-name: shadowPulse;
  animation-duration: 1.5s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}

.ServiceHeader-headarea-content {
  text-align: center;
  margin: 40px 0;
}
.ServiceHeader-relink-btn {
  border: none;
  background: #fff;
  padding: 0;
}
.active.ServiceContentA-conlistgroup {
  background-color: #01adef;
  border-color: #01adef;
}
.ServiceContentA-conlistgroup {
  border: 1px solid #fff;
  width: 100%;
}
.ServiceContentA-border-default .list-group-item-action.active {
  background-color: #01adef;
  border-color: #01adef;
}
.list-group-item.active,
.list-group-item.active:focus,
.list-group-item.active:hover {
  z-index: 0;
}
.list-group-item.active .ServiceContentA-btn-typecar {
  color: #fff;
}
.list-group-item.active:focus,
.list-group-item.active:hover {
  background-color: #01adef;
  /* border-color: #fff; */
}
.ServiceHeader-buildbtn-headl,
.ServiceHeader-buildbtn-headr {
  color: #fff;
}
.ServiceHeader-buildbtn-headl {
  padding: 15px 36px;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  border-right-color: #fff;
}
/* .row .ServiceContentA-remart .ServiceContentA-confontsidel {
    color: #fff;
} */
/* .ServiceContentA-btn-typecar.ServiceContentA-btn-typecar-test {

} */
.list-group-item-action .sub-item {
  display: none;
}
.list-group-item-action.active .sub-item {
  display: block;
}

.list-group-item-action.active .sub-item.active {
  /* color: #d9534f; */
}

.ServiceHeader-buildbtn-headr {
  padding: 15px 46px;
  background: #58595b;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  border-left-color: #fff;
}
.ServiceContentA-border-default {
  border: 1px solid #d2d3d5;
  margin-right: 0;
  margin-left: 0;
  margin-top: 0;
}
.container .row .ServiceContentA-remart {
  margin-top: 15px;
}
.ServiceContentA-btn-typecar {
  color: #01adef;
  padding: 15px 30px;
}
.list-group-item-action.active .ServiceContentA-btn-typecar {
  border-bottom: 1px solid #fff;
  margin-bottom: 10px;
}
.ServiceContentA-floatrbtn-typecar {
  float: right;
}
.row .col-md-6.ServiceContentA-remart-imgcontent {
  margin-top: 0;
}
.ServiceContentA-setmar-sider {
  margin-top: 35px;
}
.ServiceContentA-span-blue {
  color: #01adef;
}
.ServiceContentA-span-gray {
  color: #a8a9ac;
}
.ServiceContentA-conhead-font {
  font-size: 18px;
  font-weight: 600;
}
.ServiceContentA-marb-headsider {
  margin-bottom: 15px;
}
a {
  cursor: pointer;
}

/* Control Table */
.ServiceContentA-confont-tbl {
  width: 100%;
  font-size: 12px;
  line-height: 1.8;
}
.ServiceContentA-con-tblth {
  width: 35%;
}
.ServiceContentA-con-tbltdc {
  width: 9%;
}
.ServiceContentA-conbg-travel {
  background-size: cover;
  background-position: center;
  height: 200px;
}
.ServiceContentA-setmarb-cententfoot {
  margin-bottom: 40px;
}
.ServiceContentA-contxt-cententfoot {
  color: #fff;
  text-align: center;
  padding-top: 47px;
  position: relative;
}
.ServiceContentA-conspan-disinblock {
  display: inline-block;
  font-size: 20px;
  position: relative;
  top: 24px;
  left: 25px;
}
.ServiceContentA-setbg-opacity {
  background: #055cab;
  position: absolute;
  height: 100%;
  width: 95%;
  opacity: 0.5;
}
.ServiceContentA-conlogol-travel {
  width: 100px;
}
.ServiceContentA-conlogor-corporate {
  width: 100px;
}
.ServiceContentA-conpo-subcar {
  position: absolute;
  top: 79%;
  width: 70px;
  opacity: 0.7;
}
.ServiceContentA-conpo-subcar1 {
  margin-left: -525px;
  transition: 0.3s ease;
}
.ServiceContentA-conpo-subcar2 {
  margin-left: -440px;
  transition: 0.3s ease;
}
.ServiceContentA-conpo-subcar3 {
  margin-left: -355px;
  transition: 0.3s ease;
}
.ServiceContentA-conpo-subcar4 {
  margin-left: -270px;
  transition: 0.3s ease;
}

.ServiceContentA-conpo-subcar1:hover {
  transform: scale(1.5, 1.5);
  margin-left: -498px;
  width: 90px;
  top: 68%;
  opacity: 1;
  z-index: 99;
}
.ServiceContentA-conpo-subcar2:hover {
  transform: scale(1.5, 1.5);
  margin-left: -440px;
  width: 90px;
  top: 68%;
  opacity: 1;
  z-index: 99;
}
.ServiceContentA-conpo-subcar3:hover {
  transform: scale(1.5, 1.5);
  margin-left: -355px;
  width: 90px;
  top: 68%;
  opacity: 1;
  z-index: 99;
}
.ServiceContentA-conpo-subcar4:hover {
  transform: scale(1.5, 1.5);
  margin-left: -264px;
  width: 90px;
  top: 68%;
  opacity: 1;
  z-index: 99;
}

@media (max-width: 1200px) {
  .ServiceContentA-con-tblth {
    width: 44%;
  }
  .ServiceContentA-btn-typecar {
    padding: 10px 0;
  }
  .ServiceContentA-setmar-sider {
    margin-top: 25px;
  }
  .ServiceContentA-setbg-opacity {
    width: 94%;
  }
  .ServiceContentA-conpo-subcar {
    width: 60px;
  }
  .ServiceContentA-conpo-subcar1 {
    margin-left: -425px;
  }
  .ServiceContentA-conpo-subcar2 {
    margin-left: -350px;
  }
  .ServiceContentA-conpo-subcar3 {
    margin-left: -275px;
  }
  .ServiceContentA-conpo-subcar4 {
    margin-left: -200px;
  }
  .ServiceContentA-conpo-subcar1:hover,
  .ServiceContentA-conpo-subcar2:hover,
  .ServiceContentA-conpo-subcar3:hover,
  .ServiceContentA-conpo-subcar4:hover {
    top: 67%;
    transform: scale(1.2);
  }
  .ServiceContentA-conpo-subcar1:hover {
    margin-left: -430px;
  }
  .ServiceContentA-conpo-subcar2:hover {
    margin-left: -360px;
  }
  .ServiceContentA-conpo-subcar3:hover {
    margin-left: -290px;
  }
  .ServiceContentA-conpo-subcar4:hover {
    margin-left: -220px;
  }
}

@media (max-width: 992px) {
  .ServiceContentA-conpo-subcar1:hover,
  .ServiceContentA-conpo-subcar2:hover,
  .ServiceContentA-conpo-subcar3:hover,
  .ServiceContentA-conpo-subcar4:hover {
    top: 76%;
    transform: scale(1.4);
  }
  .ServiceContentA-conpo-subcar1:hover {
    margin-left: -668px;
  }
  .ServiceContentA-conpo-subcar2:hover {
    margin-left: -560px;
  }
  .ServiceContentA-conpo-subcar3:hover {
    margin-left: -451px;
  }
  .ServiceContentA-conpo-subcar4:hover {
    margin-left: -340px;
  }

  .ServiceContentA-remart {
    margin-bottom: 30px;
  }
  .ServiceContentA-marb-headsider {
    text-align: center;
  }
  .ServiceContentA-confont-tbl {
    font-size: 13px;
  }
  .ServiceContentA-con-tblth {
    width: 46%;
  }
  .ServiceContentA-txtcen-mo {
    text-align: center;
    margin-bottom: 35px;
  }
  .ServiceContentA-setbg-opacity {
    width: 96%;
  }
  .ServiceContentA-conpo-subcar {
    width: 90px;
  }
  .ServiceContentA-conpo-subcar1 {
    margin-left: -670px;
  }
  .ServiceContentA-conpo-subcar2 {
    margin-left: -560px;
  }
  .ServiceContentA-conpo-subcar3 {
    margin-left: -450px;
  }
  .ServiceContentA-conpo-subcar4 {
    margin-left: -340px;
  }
}
@media (max-width: 768px) {
  .OurService-car-left {
    display: none;
  }

  .OurService-car-right {
    text-align: center;
  }

  .OurService-car-right .button {
    margin-left: auto;
    margin-right: auto;
  }

  .OurService-book-now-text {
    font-size: 20px;
  }

  .section-header {
    font-size: 24px !important;
    margin-bottom: 20px !important;
  }

  .ServiceContentA-conpo-subcar1:hover,
  .ServiceContentA-conpo-subcar2:hover,
  .ServiceContentA-conpo-subcar3:hover,
  .ServiceContentA-conpo-subcar4:hover {
    transform: scale(1.4);
    top: unset;
    margin-left: 20px;
  }
  .ServiceContentA-bg-default {
    background: #eaeaea;
  }
  /* .ServiceContentA-btn-typecar {
    } */
  .ServiceContentA-conpo-subcar {
    width: 90px;
    position: unset;
    top: unset;
    margin: 20px 20px;
    opacity: 1;
  }
  .ServiceContentA-consubimg-cenmo {
    text-align: center;
  }
  .ServiceContentA-border-default {
    border: unset;
  }
  .container .row .ServiceContentA-remart {
    padding: 0;
  }
  .ServiceContentA-txtcen-mo {
    background: #fff;
    margin-top: 0;
    padding: 25px 0;
  }
  .row .col-md-6.ServiceContentA-remart-mo,
  .row .col-md-3.ServiceContentA-remart {
    margin-top: 0;
  }
  .row .col-md-6.ServiceContentA-remart-imgcontent {
    margin-bottom: 0px;
    margin-left: -15px;
    margin-right: -15px;
  }
  .row .ServiceContentA-remart.ServiceContentA-setmart-mobile {
    margin-top: 30px;
  }
}
@media (max-width: 630px) {
  .ServiceContentA-conpo-subcar {
    margin: 20px 15px;
  }
}

@media (max-width: 500px) {
  .ServiceContentA-setbg-opacity {
    width: 93%;
  }
  .ServiceContentA-conpo-subcar {
    margin: 20px 8px;
  }
}

@media (max-width: 480px) {
  .ServiceContentA-conpo-subcar {
    width: 75px !important;
  }
  .ServiceContentA-conpo-subcar1:hover,
  .ServiceContentA-conpo-subcar2:hover,
  .ServiceContentA-conpo-subcar3:hover,
  .ServiceContentA-conpo-subcar4:hover {
    transform: scale(1.4);
    top: unset;
    margin-left: 8px;
  }
  .ServiceHeader-buildbtn-headl {
    padding: 13px 18px;
    font-size: 13px;
  }
  .ServiceHeader-buildbtn-headr {
    padding: 13px 25px;
    font-size: 13px;
  }
  .ServiceContentA-conlogol-travel {
    width: 60px;
  }
  .ServiceContentA-conlogor-corporate {
    width: 55px;
  }
  .ServiceContentA-conspan-disinblock {
    font-size: 16px;
    top: 18px;
  }
  .ServiceContentA-setpadt-mo {
    padding-top: 20px;
  }
}
@media (max-width: 413px) {
  .ServiceContentA-setbg-opacity {
    width: 92.8%;
  }
  .ServiceContentA-conpo-subcar {
    width: 65px !important;
  }
}

@media (max-width: 376px) {
  .ServiceContentA-setbg-opacity {
    width: 92%;
  }
}

@media (max-width: 360px) {
  .ServiceContentA-setbg-opacity {
    width: 91.8%;
  }
  .ServiceContentA-conpo-subcar {
    width: 60px !important;
  }
}

@media (max-width: 320px) {
  .ServiceContentA-setbg-opacity {
    width: 90.5%;
  }
}
