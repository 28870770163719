.Book6HeaderStatus-setpadb-conarea {
  background: #eaeaea;
}
.Book6ContentA-padmar-default {
  margin: 0 0;
  padding-bottom: 30px;
}
.BookingStep6-re-contain {
  margin-top: 0;
}
.Book6ContentA-repadlr-default {
  padding: 0;
}
.Book6ContentA-set-bghead {
  background: #055cab;
}
.Book6ContentA-head-title {
  font-size: 25px;
  color: #fff;
  padding: 20px 60px;
  font-weight: 600;
}
.Book6ContentA-set-bginfo {
  background: #fff;
  padding: 30px 60px 30px 60px;
  margin-bottom: 30px;
  color: #a8a9ac;
}
.Book6ContentA-marbet-imgtxt {
  margin-left: 10px;
}
.Book6ContentA-sub-details {
  margin-left: 17px;
  display: none;
}
.Book6ContentA-open-details {
  display: none;
}
.Book6ContentA-setmar-info {
  margin: 15px 0;
}
.row .col-xs-12.Book6ContentA-setmarb-split {
  margin-top: 0;
}
.Book6ContentA-con-BookCom {
  color: #ee4231;
  font-size: 17px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.Book6ContentA-marb-BookCom-info {
  margin-bottom: 20px;
}
.Book6ContentA-head-titleprice {
  float: right;
}
.Book6ContentA-floatr-price {
  float: right;
}

@media (max-width: 768px) {
  .Book6ContentA-set-bginfo {
    padding: 30px 20px 60px 20px;
  }
}

@media (max-width: 500px) {
  .Book6ContentA-head-title {
    font-size: 23px;
    padding: 20px 40px;
  }
  .Book6ContentA-head-titleprice {
    padding-left: 0;
  }
  .Book6ContentA-set-bginfo {
    font-size: 12px;
  }
}

@media (max-width: 360px) {
  .Book6ContentA-head-title {
    font-size: 21px;
  }
}

@media (max-width: 330px) {
  .Book6ContentA-head-title {
    font-size: 20px;
  }
}
