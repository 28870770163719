.Journey-Detail-fluid {
  position: relative;
  width: 80vw;
}

.Journey-Detail-related-img-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  column-gap: 12px;
}

.journey-detail-control-p,
.journey-detail-control-p > *,
.journey-detail-control-p > * > *,
.journey-detail-control-p > * > * > *,
.journey-detail-control-p > * > * > * > *,
.journey-detail-control-p > * > * > * > * > * {
  font-family: "Prompt" !important;
}

@media (max-width: 768px) {
  .Journey-Detail-fluid {
    width: 90vw !important;
    padding-right: 0;
    padding-left: 0;
  }

  .control-detail-related a {
    padding: 4px 30px;
    font-size: 1.2rem;
  }
}
