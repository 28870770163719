.ContactContentA-contain-default {
    text-align: center;
}
.container-title .ContactContentA-conhr-head {
    min-width: 50px;
}
.container-title .ContactContentA-hr-promo {
    background: #01adef;
    height: 7px;
    display: inline-block;
    margin-left: 0px;
}
.container-title .ContactContentA-confont-header {
    font-size: 35px;
    color: #01adef;
}
.ContactContentA-setmarlr-de {
    margin: 70px 150px;
}
.ContactContentA-setmarb-belogo {
    margin-bottom: 35px;
}

.ContactContentA-setmarb-infocarprice {
    margin-bottom: 25px;
}
.ContactContentA-setmarb-infocarpricea {
    padding-top: 32px;
    margin-top: -34px;
}
.ContactContentA-setfloat-infocarprice {
    float: left;
    margin-right: 20px;
}
.ContactContentA-conwlogo-carprice {
    width: 40px;
}
.ContactContentA-confont-infocarprice {
    font-size: 12px;
}
.ContactContentA-conmarb-conform {
    margin-bottom: 20px;
}
.ContactContentA-coninput-form {
    font-size: 12px;
    border: 1px solid #bce0fd;
}
.ContactContentA-coninput-mail,
.ContactContentA-coninput-name,
.ContactContentA-coninput-num {
    height: 40px;
}
.ContactContentA-mobtn-primary {
    background: #01adef;
    border-radius: 5px;
    width: 35%;
    font-size: 12px;
    border-color: #01adef;
    padding: 13px 0;
    letter-spacing: 2px;
}
.ContactContentA-confont-headsidel {
    color: #01adef;
    font-size: 20px;
    font-weight: 600;
}
.main-slider .item.ContactContentA-conbg-head {
    /* height: 350px; */
    background-position: center;
}
.ContactContentA-confontsidel-subh {
    color: #818285;
}
.ContactContentA-confontsidel-de {
    color: #a8a9ac;
    font-size: 12px;
}
.ContactContentA-confontsidel-mail,
.ContactContentA-confontsidel-phone,
.ContactContentA-confontsidel-face {
    font-size: 16px;
    font-weight: 600;
}

.ContactContentA-confontsidel-phone {
    color: #ee4231;
}
.ContactContentA-confontsidel-mail {
    color: #a8a9ac;
}
.ContactContentA-confontsidel-face {
    color: #01adef;
}
.ContactContentA-confontsidel-deface,
.ContactContentA-confontsidel-dephone,
.ContactContentA-confontsidel-demail {
    color: #a8a9ac;
    font-size: 12px;
}
@media (max-width: 1200px) {
    .ContactContentA-mobtn-primary {
        width: 50%;
    }
}

@media (max-width: 992px) {
    .ContactContentA-contain-default {
        margin-top: 60px;
    }
    .ContactContentA-conbtn-mobile {
        text-align: center;
    }
    .ContactContentA-mobtn-primary {
        width: 50%;
    }
    .ContactContentA-setmarlr-de {
        margin: 40px 150px 70px;
    }
}

@media (max-width: 768px) {
    .ContactContentA-setmarlr-de {
        margin: 40px 20px 70px;
    }
    .ContactContentA-setmarl-headmo {
        margin-left: 10px;
    }
}

@media (max-width: 550px) {
    .ContactContentA-mobtn-primary {
        width: 60%;
    }
}

@media (max-width: 400px) {
    .ContactContentA-mobtn-primary {
        width: 100%;
    }
}
