.mt0 {
    margin-top: 0 !important;
}

.BookHisContentA-buildbg-bh {
    background: #055cab;
    color: #fff;
    font-size: 35px;
    letter-spacing: 3px;
    text-align: center;
    padding: 15px;
}

.BookHisContentA-setmarb-head {
    margin-bottom: 15px;
}

.BookHisContentA-setbgcolor-noac {
    background: #d2d3d5;
}

.BookHisContentA-setbgcolor-ac {
    background: #01adef;
}

.BookHisContentA-setmarlr-de {
    margin: 70px 0px 50px 185px;
}

.BookHisContentA-buildbg-info {
    padding-left: 0;
    padding-right: 0;
    background: #fff;
    margin-left: 0px;
    margin-right: 0px;
    padding-bottom: 30px;
    margin-bottom: 15px;
}

.BookHisContentA-conw-imgsidel {
    width: 100%;
}

.BookHisContentA-conw-logode {
    width: 60%;
}

.BookHisContentA-repad-tbl {
    padding: 0;
    margin-bottom: 21px;
}

.BookHisContentA-repad-carde {
    padding: 0;
}

.BookHisContentA-confont-carde {
    color: #a8a9ac;
    font-size: 10px;
}

.BookHisContentA-setmarb-carde {
    margin-bottom: 15px;
}

.BookHisContentA-confont-ex {
    font-size: 10px;
}

.BookHisContentA-img-marr {
    margin-right: 5px;
    margin-left: 0;
}

.BookHisContentA-buildbg-info .row .BookHisContentA-remart-ex {
    margin-top: 0;
}

.BookHisContentA-confont-typec {
    font-size: 16px;
    font-weight: 600;
}

.BookHisContentA-confont-pick {
    color: #58595b;
}

.BookHisContentA-confont-re {
    color: #58595b;
    margin-top: 15px;
}

.BookHisContentA-confont-cardetitle {
    font-size: 13px;
    font-weight: 600;
}

.BookHisContentA-confont-extitle {
    font-size: 20px;
    color: #01adef;
    font-weight: 600;
}

.BookHisContentA-confont-rowa,
.BookHisContentA-confont-rowc {
    font-weight: 600;
}

.BookHisContentA-confont-rowb {
    font-size: 13px;
    font-weight: 600;
    color: #a8a9ac;
}

.BookHisContentA-setup-select {
    padding-top: 2px;
}

.BookHisContentA-buildlink-head {
    color: #fff;
    padding: 20px 30px;
}

.BookHisContentA-setlogo-booklink {
    width: 39px;
    margin-right: 30px;
}

.BookHisContentA-setlogo-prolink {
    width: 30px;
    margin-right: 30px;
}

.BookHisContentA-set-select {
    border-color: #bce0fd;
    color: #01adef;
    font-size: 10px;
    margin-left: 5px;
    background: #fff;
    width: 37px;
}

@media (min-width: 993px) {
    .row.ProfileContentA-setmarb-fform {
        margin-bottom: 0px;
        margin-top: -50px;
    }

    .BookHisContentA-buildlink-head {
        padding: 20px 7px;
    }

    .BookHisContentA-setlogo-booklink {
        width: 28px;
        margin-right: 9px;
    }

    .BookHisContentA-setlogo-prolink {
        width: 28px;
        margin-right: 9px;
    }

    .BookHisContentA-setbgcolor-ac {
        padding: 22px 7px;
    }

    .BookHisContentA-repad-booking {
        padding: 0;
        margin-bottom: 20px;
    }

    .BookHisContentA-confont-cardetitle {
        font-size: 11px;
    }

    .BookHisContentA-reset-wV {
        width: 20%;
    }

    .BookHisContentA-conlogo-carde {
        text-align: unset;
        margin-left: 17px;
    }

    .radio .BookHisContentA-setup-select {
        padding-left: 0px;
    }

    .radio .BookHisContentA-setup-select::before {
        width: 14px;
        height: 14px;
    }

    .radio .BookHisContentA-setup-select::after {
        left: 4.5px;
        top: 6.5px;
        width: 5px;
        height: 5px;
    }

    .BookHisContentA-conlogo-extra {
        width: 25px;
    }

    .BookHisContentA-remart-extra {
        margin-top: 11px;
        margin-right: 5px;
    }

    .BookHisContentA-remar5t-extra {
        margin-top: 0px;
    }

    .BookHisContentA-img-marr {
        margin-right: 3px;
        margin-left: -5px;
    }

    .BookHisContentA-addmarr-ex {
        margin-right: 5px;
    }

    .BookHisContentA-remarrtxt-ex {
        margin-right: -10px;
    }

    .BookHisContentA-conwlogo-carde {
        width: 25px;
    }

    .BookHisContentA-mow-seat {
        width: 17%;
    }

    .BookHisContentA-mow-air {
        width: 29%;
    }

    .BookHisContentA-mow-lugg {
        width: 31%;
    }

    .BookHisContentA-confont-carde {
        margin-left: -5px;
    }

    .BookHisContentA-confont-pick,
    .BookHisContentA-confont-re {
        font-size: 13px;
    }

    .BookHisContentA-confont-sider {
        text-align: right;
        margin-right: 15px;
        padding-top: 260px;
    }

    .BookHisContentA-repadr-prolink {
        position: absolute;
        margin-left: -186px;
        top: 40px;
        width: 207px;
    }

    .BookHisContentA-repadl-booklink {
        position: absolute;
        margin-left: -186px;
        top: 122px;
        width: 208px;
    }

    .BookHisContentA-conlogo-carde {
        text-align: center;
    }
}

@media (min-width: 1200px) {
    .BookHisContentA-conlogo-carde {
        text-align: unset;
    }

    .BookHisContentA-reset-wV {
        margin-top: 24px;
    }

    .BookHisContentA-img-marr {
        margin-right: 8px;
        margin-left: 0px;
    }

    .BookHisContentA-confont-sider {
        padding-top: 260px;
    }

    .BookHisContentA-mow-seat {
        width: 24%;
    }

    .BookHisContentA-mow-air {
        width: 27%;
    }

    .BookHisContentA-mow-lugg {
        width: 28%;
    }
}

@media (max-width: 992px) {
    .radio .BookHisContentA-setup-select::after {
        top: 7px;
    }

    .BookHisContentA-setlogo-booklink {
        width: 39px;
        margin-right: 10px;
    }

    .BookHisContentA-setlogo-prolink {
        width: 30px;
        margin-right: 15px;
    }

    .BookHisContentA-repadr-prolink {
        padding-right: 0;
    }

    .BookHisContentA-repadl-booklink {
        padding-left: 0;
    }

    .row .col-xs-6.BookHisContentA-repadl-booklink,
    .row .col-xs-6.BookHisContentA-repadr-prolink {
        margin-top: 18px;
    }

    .BookHisContentA-setmarlr-de {
        margin: 70px 0px 50px 0px;
    }

    .BookHisContentA-repad-tbl {
        padding: 0 15px;
    }

    .BookHisContentA-conw-logode {
        width: 30%;
    }

    .BookHisContentA-addpadinfo-fmo {
        padding: 0 80px;
    }

    .BookHisContentA-mow-seat {
        width: 50%;
        margin-left: 15px;
    }

    .BookHisContentA-mow-air {
        width: 47%;
    }

    .row .col-xs-3.BookHisContentA-mow-lugg {
        margin-top: 0;
        margin-left: 15px;
        width: 100%;
    }

    .BookHisContentA-setfloat-lug {
        float: left;
        width: 50%;
    }

    .BookHisContentA-resetmar-default {
        margin-left: 15px;
        margin-right: 15px;
    }

    .row .col-md-6.BookHisContentA-repad-tbl {
        margin-top: 0;
    }

    .BookHisContentA-reset-wV {
        margin-left: 100px;
    }

    .BookHisContentA-confont-cardetitle {
        font-size: 16px;
    }

    .row.BookHisContentA-addmart-extra {
        margin-top: 30px;
        padding: 0 100px 0 80px;
    }

    .BookHisContentA-setmoprice-footer {
        text-align: right;
        margin-right: 101px;
    }
}

@media (max-width: 768px) {
    .BookHisContentA-mow-seat {
        width: 48%;
    }

    .BookHisContentA-setfloat-lug {
        width: 48%;
    }

    .row .BookHisContentA-addmart-extra {
        margin-top: 30px;
    }
}

@media (max-width: 650px) {
    .BookHisContentA-reset-wV {
        margin-left: 15px;
        text-align: center;
    }
}

@media (max-width: 550px) {
    .BookHisContentA-reset-wV {
        margin-left: 0;
        text-align: center;
    }

    .BookHisContentA-addpadinfo-fmo {
        padding: 0 15px;
    }

    .row .BookHisContentA-addmart-extra {
        padding: 0 15px;
    }

    .BookHisContentA-setmoprice-footer {
        margin-right: 15px;
    }

    .BookHisContentA-buildlink-head {
        padding: 20px 13px;
    }

    .BookHisContentA-confont-rowa,
    .BookHisContentA-confont-rowc {
        font-size: 17px;
    }

    .BookHisContentA-setlogo-prolink {
        width: 25px;
    }

    .BookHisContentA-setlogo-booklink {
        width: 33px;
    }
}

@media (max-width: 400px) {
    .BookHisContentA-addpadinfo-fmo {
        padding: 0 0;
    }

    .row .BookHisContentA-addmart-extra {
        padding: 0 0;
    }

    .BookHisContentA-setmoprice-footer {
        margin-right: 15px;
    }

    .BookHisContentA-buildbg-bh {
        font-size: 28px;
    }
}

@media (max-width: 361px) {
    .BookHisContentA-buildlink-head {
        padding: 20px 11px;
    }

    .ProfileContentA-confont-link {
        font-size: 11px;
    }

    .BookHisContentA-setlogo-booklink {
        margin-right: 0px;
    }

    .BookHisContentA-setlogo-prolink {
        margin-right: 0px;
    }

    /* .ProfileContentA-confont-linkpro {
        margin-left: 20px;
    } */
}

@media (max-width: 330px) {
    .BookHisContentA-setlogo-booklink {
        width: 33px;
    }

    .BookHisContentA-setlogo-prolink {
        width: 26px;
    }

    .BookHisContentA-mow-seat {
        width: 42%;
    }

    .BookHisContentA-setfloat-lug {
        width: 42%;
    }

    .BookHisContentA-buildlink-head {
        padding: 20px 5px;
    }

    .BookHisContentA-buildbg-bh {
        font-size: 23px;
    }
}