.Branch-container-fluid {
  position: relative;
  width: 80vw;
}

.Branch-contain-default {
  text-align: center;
  margin-top: 135px;
}
.main-slider .item.BranchContentA-conbg-head {
  height: 350px;
  /* background: url("images/BranchLocation/bg-branch-header@2x.png"); */
  background-position: center;
}
.container-title .BranchContentA-conhr-head {
  min-width: 90px;
}
.BranchContentB-repadl-contentsider {
  padding-left: 0;
}
.BranchContentA-hidechevron {
  display: none;
}
.BranchContentA-setbg-opacity {
  background: #055cab;
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.5;
}
.Branch-contain-default {
  position: relative;
}

.BranchContentB-contxtcen-incol {
  text-align: center;
  background: #effaff;
  margin-left: 0;
  margin-right: 0;
}
.BranchContentB-setmar-default {
  margin-top: 70px;
  margin-bottom: 50px;
}

.mo-list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.mo-list-group-item:last-child {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.mo-list-group-item.active {
  background-color: #01adef;
  border-color: #01adef;
}
.mo-list-group-item {
  border: 1px solid #fff;
  background-color: unset;
  padding: 25px 0;
}

.BranchContentB-hideimg-mo {
  display: none;
}

.BranchContentB-confont-region {
  color: #01adef;
  font-size: 17px;
}
.row .col-md-6.BranchContentB-content-sidel {
  margin-top: 0;
  background: #fff;
  border: 1px solid #fff;
}
.BranchContentB-con-row-sidel {
  margin-right: 0;
  margin-left: 0;
}
.row .col-md-6.BranchContentB-content-sider {
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
}
.row .BranchContentB-remart-contentl .BranchContentB-remart-location {
  margin-top: 0;
}
.BranchContentB-remart-contentl .BranchContentB-remart-radio {
  margin-top: 0;
}
.row .col-md-12.BranchContentB-remart-contentl,
.row .col-md-12.BranchContentB-remart-contentr {
  margin-top: 0;
}
.BranchContentB-confontinfo-sider {
  font-size: 15px;
}

.BranchContentB-padfor-contentr,
.BranchContentB-padfor-contentl {
  padding: 50px 30px;
}
.BranchContentB-splitrow-marb {
  margin-bottom: 15px;
}
.BranchContentB-confont-headlocation {
  color: #01adef;
  font-size: 17px;
}
.BranchContentB-contxtcen-incol .mo-list-group-item.active:focus {
  border-color: #01adef;
}
.BranchContentB-confont-location {
  font-size: 12px;
}
.mo-list-group-item-p1,
.mo-list-group-item-p2,
.mo-list-group-item-p3,
.mo-list-group-item-p4,
.mo-list-group-item-p5 {
  float: left;
  border-bottom: unset;
}

.list-group-item.active .BranchContentB-hideimg-mo {
  display: inline-block;
}
.list-group-item.active .BranchContentB-confont-region {
  color: #fff;
}
@media (min-width: 1200px) {
  .mo-list-group-item-p1,
  .mo-list-group-item-p2,
  .mo-list-group-item-p3,
  .mo-list-group-item-p5 {
    width: 18.99999997%;
  }
  .mo-list-group-item-p4 {
    width: 23.99999997%;
  }
}

@media (max-width: 1200px) {
  .BranchContentB-confont-region {
    font-size: 15px;
  }
  .mo-list-group-item-p1,
  .mo-list-group-item-p2,
  .mo-list-group-item-p3,
  .mo-list-group-item-p5 {
    width: 18.99999997%;
  }
  .mo-list-group-item-p4 {
    width: 23.99999997%;
  }
}

@media (max-width: 992px) {
  .BranchContentB-addmarl-onmo {
    margin-left: 45px;
  }
  .BranchContentB-remart-contentl .row .BranchContentB-remart-onmobile {
    margin-top: 0;
  }
  .BranchContentB-remart-locaonmo {
    margin-top: 0;
  }
  .mo-list-group-item-p1,
  .mo-list-group-item-p3,
  .mo-list-group-item-p5 {
    width: 17.99999997%;
  }
  .mo-list-group-item-p4 {
    width: 26.99999997%;
  }
  .BranchContentB-confont-region {
    font-size: 14px;
  }
  .row .col-md-6.BranchContentB-remartloca-formo {
    margin-top: 0;
  }
  .BranchContentB-remartradio-formo {
    margin-top: -4px;
  }
}

@media (max-width: 768px) {
  .Branch-container-fluid {
    width: 90vw !important;
    padding-right: 0;
    padding-left: 0;
  }

  .BranchContentB-addmarl-onmo {
    margin-left: 0px;
  }
  .mo-list-group-item-p1,
  .mo-list-group-item-p2,
  .mo-list-group-item-p3,
  .mo-list-group-item-p4,
  .mo-list-group-item-p5 {
    width: 100%;
    border: unset;
  }
  .mo-list-group-item {
    display: none;
  }
  .mo-list-group-item.active {
    display: block;
  }

  .BranchContentB-confont-region {
    font-size: 18px;
  }
  .BranchContentB-confont-headlocation {
    font-size: 18px;
  }
  .BranchContentB-repadl-contentsider {
    padding-left: 0;
  }
  .BranchContentB-confontinfo-sider {
    font-size: 13px;
  }
  .BranchContentB-confont-location {
    font-size: 13px;
  }
  .BranchContentA-conchevron {
    display: flex;
    position: absolute;
    top: -2px;
    opacity: 0.4;
    transition: transform 0.3s, opacity 0.3s;
  }
  .BranchContentA-conchevron:hover {
    cursor: pointer;
    opacity: 1;
    transform: scale(1.3);
  }
  .BranchContentA-conchevronl {
    left: 5%;
  }
  .BranchContentA-conchevronr {
    right: 5%;
  }
}

@media (max-width: 330px) {
  .BranchContentB-addmarl-onmo {
    margin-left: -15px;
  }
}

.radio .BranchContentB-confont-location::before {
  margin-left: -43px;
}

.myMarker {
  position: absolute;
  transform: translate(-50%, -80%);
  width: 40px;
}
