.Service-box {
  background-color: transparent;

  margin-bottom: 10px;
  cursor: pointer;
  height: 250px;
  width: 100%;
  color: #fff;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;

  position: relative;
}

.Service-box .bg-img {
  box-shadow: inset 0 0 0 2000px rgba(5, 92, 171, 0.5);
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.Service-box .bg-img::before {
  content: "";
  display: none;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(52, 73, 94, 0.6);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.Service-box:hover .bg-img:before,
.Service-box:focus .bg-img:before {
  display: block;
}

.Service-box:hover .bg-img,
.Service-box:focus .bg-img {
  transform: scale(1.1);
}

.Service-box button {
  text-align: center;
  width: 60%;
  min-height: 40px;
  font-family: Prompt;
  border: 2px solid #fff;
  opacity: 0.9984;
  background: rgba(255, 255, 255, 0.2);
  font-weight: 700;
  font-size: 16px;

  position: absolute;
  z-index: 1;
}

.Service-box > .desc {
  text-align: center;
  font-family: Prompt;
  font-size: 12px;
  font-weight: 400;
  color: #fff;

  position: absolute;
  z-index: 1;
  top: 60%;
}

.LeftArrow {
  display: none;

  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  top: 105px;
  left: -20px;

  cursor: pointer;
  transition: all 150ms ease-in-out;
}

.RightArrow {
  display: none;

  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  top: 105px;
  right: -20px;
  transform: rotate(180deg);

  cursor: pointer;
  transition: all 150ms ease-in-out;
}

.LeftArrow:hover,
.RightArrow:hover {
  opacity: 0.8;
}

.LeftArrow:active {
  transform: scale(0.9);
}

.RightArrow:active {
  transform: scale(0.9) rotate(180deg);
}

@media (max-width: 768px) {
  .Service-box {
    width: 350px;
  }

  .LeftArrow,
  .RightArrow {
    display: block;
  }
}
