.BookContentA-hide-options {
  margin-top: 30px;
}
.BookContentA-mart-pay.left strong,
.BookContentA-mart-pay.right strong {
  font-size: 16px;
  /* line-height: 1; */
}
.wrap-payment-info .row  > div  {
  padding: 30px;
}
.wrap-payment-info ul {
  list-style: none;
}
.wrap-payment-info li {
  margin-left: 20px;
}
.wrap-payment-info li::before {
  content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #055CAB; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

.row .col-xs-12.BookContentA-mart-pay.left {
  text-align: right;
  margin-top: 170px;
  padding: 0 30px 0 5px;
}
.BookContentA-p-pick-re {
  font-size: 15px;
}
@media (max-width: 600px) {
  .BookHeaderStatus-reon-mobile {
    display: none;
  }
}

@media (max-width: 1200px) {
  .BookContentA-mart-pay.left strong,
  .BookContentA-mart-pay.right strong {
    font-size: 16px;
    /* line-height: 1; */
  }
}

@media (max-width: 992px) {
  .row .col-xs-12.BookContentA-mart-pay.left {
    text-align: right;
    margin-top: 30px;
    padding: 0 60px 0 5px;
  }
}
