#resume-pdf-input::-webkit-file-upload-button {
  display: none;
}

#resume-pdf-input {
  text-align: center;
  padding-top: 14px;
}

.Resume-input-container {
  margin-bottom: 10px;

  display: flex;
  flex-direction: row;
  align-items: center;

  column-gap: 12px;
}

.Resume-input-container button {
  width: 100%;

  border-radius: 3px;
  background: #055cab;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  padding-right: 20px;
  color: #ffffff;
  font-weight: bold;

  text-align: center;
  font-size: 13px;

  border: none;
  outline: none;

  transition: all 0.3s ease-in-out;
}

.Resume-input-container button:hover {
  background: #044989;
  color: #ffffff;
  text-decoration: none;
}

.Hr-banner-container {
  padding: 50px 0px !important;
  width: 60vw;
  margin: 0 auto;
}

.Hr-banner-tel-email-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.Career-qualification-button {
  background-color: #01adef;
  color: #ffffff;
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 600;
  outline: none;
  border: 1px solid transparent;

  transition: all 300ms;
  width: fit-content;
}

.Career-qualification-button:hover {
  color: #0193cd;
  border-color: #0193cd;
  background-color: #ffffff;
}

.Career-container-fluid {
  position: relative;
  width: 80vw;
}

.CareerContentA-setbg-opacity {
  background: #055cab;
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.5;
}
.main-slider .item.CareerContentA-conbg-head {
  height: 350px;
  background-position: center;
}
.CareerContentA-contain-default {
  text-align: center;
  margin-top: 135px;
  position: relative;
}
.CareerContentA-repadb-con {
  padding-bottom: 0;
}
.container-title .CareerContentA-conhr-head {
  min-width: 90px;
}
.CareerContentB-conw-logoprop {
  width: 30px;
}

.container-title .CareerContentA-hr-promo {
  background: #fff;
  height: 7px;
  display: inline-block;
  margin-left: -2px;
}

.CareerContentB-setpad-default {
  background: #fff;
  padding: 20px 35px;
  overflow-y: scroll;
  height: 300px;
}
.CareerContentB-confont-titleprop {
  font-size: 14px;
}
.CareerContentB-confont-head {
  color: #01adef;
  font-weight: 600;
  margin-bottom: 15px;
}
.CareerContentB-hr-info {
  height: 1px;
  background: #d2d3d5;
}
.row .col-xs-6.CareerContentB-repad-info {
  margin-top: 15px;
  padding: 0;
}
.CareerContentB-conwlogo-gps {
  width: 17px;
}
.CareerContentB-conwlogo-money {
  width: 26px;
}
.CareerContentB-setfloat-infocarprice {
  float: left;
  margin-right: 10px;
}
.CareerContentB-setmarb-infocarpricea {
  padding-top: 37px;
  margin-top: -34px;
}
.CareerContentB-confont-infocarprice {
  font-size: 10px;
}
.CareerContentB-remar-rowdefault {
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
}
.CareerContentB-buildbtn-info {
  background: #01adef;
  border: 1px solid #01adef;
  color: #fff;
  width: 60%;
  padding: 8px 0;
}
.CareerContentB-conpolygon-carhome {
  width: 5px;
  margin-left: 15px;
  padding-bottom: 2px;
}
.CareerContentB-addmart-prop {
  margin-top: 10px;
}
.CareerContentB-setpad-default .row .CareerContentB-remart-btnprop {
  margin-top: 0;
}
.CareerContentB-setpad-default .row .CareerContentB-remart-btnpropa {
  margin-top: 4px;
}
.CareerContentB-confont-prop {
  font-size: 11px;
}
/* .CareerContentB-setminh {
  min-height: 90px;
} */
.CareerContentA-setmarb-afboxcareer {
  margin-bottom: 50px;
}
.CareerContentB-contain-default {
  text-align: center;
  margin-top: 0;
  position: relative;
}

.main-slider .item.CareerContentB-conbg-head {
  height: unset;
  background-position: center;
}
.CareerContentB-setpad-infocontact {
  padding: 50px 350px 100px;
}
.CareerContentB-setfloatl-a {
  float: left;
}
.CareerContentB-setfloatl-b {
  float: right;
  margin-top: 12px;
}
.CareerContentB-conw-logophone-con {
  width: 35px;
  margin-top: -13px;
  margin-right: 20px;
}
.CareerContentB-conw-logomail-con {
  width: 50px;
  margin-right: 20px;
}
.CareerContentB-conspan-fr {
  margin-bottom: 15px;
  color: #fff;
}
.CareerContentB-fontnum-phone {
  font-size: 35px;
  color: #fff;
}
.CareerContentB-fontemail-th {
  color: #fff;
}
.CareerContentB-repad-prop {
  padding: 0;
}
/* .CareerContentB-addmarl-prop {
  margin-left: 15px;
} */
.CareerContentC-setbg-opacity {
  background: #fff;
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.7;
}
.main-slider .item.CareerContentC-conbg-head {
  background-position: center;
  height: 500px;
}
.CareerContentC-contain-default {
  position: relative;
}
.CareerContentC-setmar-infofoot {
  margin: 35px 310px;
}
.CareerContentC-conspan-front {
  background: #01adef;
  color: #fff;
  font-size: 12px;
  padding: 0 20px;
}
.CareerContentC-conmarb-input {
  margin-bottom: 10px;
}
.CareerContentC-conspan-first,
.CareerContentC-conspan-last {
  padding-right: 31px;
}
.CareerContentC-conspan-phone {
  padding-right: 55px;
}
.CareerContentC-conspan-po {
  padding-right: 49px;
}
.CareerContentC-conspan-mail {
  padding-right: 57px;
}
.CareerContentC-setmar-infofoot
  .input-group
  .input-group-btn
  .CareerContentC-buildbtn-upload {
  border-radius: 5px;
  background: #055cab;
  color: #fff;
  padding: 13px 40px;
  border-radius: 5px;
  border: 1px solid #055cab;
  margin-left: 10px;
}
.CareerContentC-buildbtn-submit {
  border-radius: 5px !important;
  background: #ee4231;
  color: #fff;
  padding: 13px 40px;
  width: 30%;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
  outline: none;
}
.CareerContentB-conpaddl-gps {
  padding-left: 27px;
  font-size: 12px;
}
.CareerContentB-conpaddl-money {
  padding-left: 36px;
}
.CareerContentB-shon-mobile {
  display: none;
}
.CareerContentC-conbtnup-mobile {
  display: none;
}
.CareerContentC-conbtnup-mobile .CareerContentC-buildbtn-upload {
  border-radius: 5px;
  background: #055cab;
  color: #fff;
  padding: 13px 40px;
  border-radius: 5px;
  border: 1px solid #055cab;
}

@media (max-width: 1200px) {
  .CareerContentB-setpad-default {
    padding: 20px 14px;
    height: 381px;
  }
  .CareerContentB-setpad-infocontact {
    padding: 50px 240px 100px;
  }
  .CareerContentC-setmar-infofoot {
    margin: 35px 200px;
  }
  .CareerContentC-buildbtn-submit {
    width: 40%;
  }
}

@media (max-width: 992px) {
  .CareerContentB-conw-logoprop {
    width: 45px;
  }
  .CareerContentB-setpad-default {
    padding: 20px 80px;
    overflow-y: unset;
    height: unset;
  }
  .CareerContentB-confont-head {
    font-size: 17px;
  }
  .CareerContentB-conwlogo-gps {
    width: 20px;
  }
  .CareerContentB-setfloat-infocarprice {
    margin-right: 15px;
  }
  .CareerContentB-conpaddl-gps {
    padding-left: 35px;
    font-size: 12px;
  }
  .CareerContentB-conpaddl-money {
    padding-left: 41px;
    font-size: 12px;
  }
  .CareerContentB-conpolygon-carhome {
    margin-left: 30px;
  }
  .CareerContentB-setpad-infocontact {
    padding: 50px 130px 100px;
  }
  .CareerContentC-setmar-infofoot {
    margin: 35px 100px;
  }
}

@media (max-width: 768px) {
  .Resume-input-container {
    flex-direction: column !important;
    align-items: flex-start;
  }

  .Resume-input-container > *:first-child {
    margin-bottom: 10px !important;
    width: 100% !important;
  }

  .Hr-banner-container {
    width: 90vw !important;
  }

  .Career-container-fluid {
    width: 90vw !important;
    padding-right: 0;
    padding-left: 0;
  }

  .CareerContentB-confont-prop {
    font-size: 12px;
  }
  .CareerContentB-buildbtn-info {
    width: 80%;
    padding: 10px 0;
  }
  .main-slider .item.CareerContentB-conbg-head {
    height: unset;
    min-height: unset;
  }
  .CareerContentB-remart-btnprop {
    text-align: center;
  }
  .CareerContentC-buildbtn-submit {
    width: 80%;
    margin-top: 15px;
  }
  .CareerContentC-conbtn-submitfoot {
    text-align: center;
  }
  .CareerContentB-setfloatl-a {
    display: none;
  }
  .CareerContentB-setfloatl-b {
    float: unset;
  }
  .CareerContentB-shon-mobile {
    display: block;
    margin-bottom: 20px;
  }
  .CareerContentB-setpad-infocontact {
    padding: 50px 130px 50px;
  }
  .CareerContentB-fontemail-th {
    display: block;
  }
  .CareerContentB-conw-logomail-con {
    margin-right: -5px;
    margin-bottom: 20px;
  }
  .CareerContentC-setmar-infofoot {
    margin: 35px 15px;
  }
  .CareerContentB-setpad-default {
    margin-left: 15px;
    margin-right: 15px;
  }

  .CareerContentC-hidemobile {
    display: none;
  }
  .CareerContentC-conbtnup-mobile {
    display: block;
  }
  .CareerContentC-conbtnup-mobile .CareerContentC-buildbtn-upload {
    width: 30%;
  }
  .main-slider .item.CareerContentC-conbg-head {
    height: 550px;
  }
}
@media (max-width: 650px) {
  .CareerContentB-setpad-default {
    padding: 20px 45px;
  }
  .CareerContentB-buildbtn-info {
    width: 100%;
  }
  .CareerContentB-setpad-infocontact {
    padding: 50px 80px 50px;
  }
}

@media (max-width: 550px) {
  .CareerContentB-setpad-infocontact {
    padding: 50px 0px 50px;
  }
  .CareerContentC-conbtnup-mobile .CareerContentC-buildbtn-upload {
    width: 40%;
  }
}
@media (max-width: 470px) {
  .CareerContentB-repad-info {
    width: 100%;
  }
  .CareerContentB-conw-logoprop {
    width: 30px;
  }
  .CareerContentB-setpad-default
    .row
    .CareerContentB-remart-btnprop.CareerContentB-repad-prop {
    margin-right: 12px;
    width: 9.666667%;
  }
}

@media (max-width: 450px) {
  .CareerContentC-conbtnup-mobile .CareerContentC-buildbtn-upload {
    width: 50%;
  }
  .CareerContentC-conspan-front {
    font-size: 10px;
    padding: 0 5px;
  }
  .CareerContentC-conspan-mail {
    padding-right: 36px;
  }
  .CareerContentC-conspan-po {
    padding-right: 29px;
  }
  .CareerContentC-conspan-phone {
    padding-right: 34px;
  }
  .CareerContentC-conspan-first,
  .CareerContentC-conspan-last {
    padding-right: 14px;
  }
  .CareerContentB-confont-head {
    font-size: 15px;
  }
  .CareerContentB-setpad-default {
    padding: 20px 25px;
  }
}

@media (max-width: 380px) {
  .CareerContentC-conbtnup-mobile .CareerContentC-buildbtn-upload {
    width: 60%;
  }
  .CareerContentB-confont-head {
    font-size: 14px;
  }
}
