.AboutContentA-setbg-opacity {
  background: #055cab;
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.6;
}

.AboutContentB-repad-sider p {
  margin-bottom: 5px;
}

.AboutContentA-contain-default {
  margin-top: 165px;
  position: relative;
}

.AboutContentA-contain-default h1 {
  font-size: 55px;
}

.AboutContentA-confont-spanhead {
  color: #fff;
}

.AboutContentB-conimg-sidel {
  width: 100%;
}

.AboutContentB-setpadtb-imgdefault {
  padding: 15px 40px 0 0;
}

.AboutContentB-setpadsider-default {
  padding: 78px 0 0 0px;
}

.AboutContentB-marb-infoa {
  padding-top: 50px;
  margin-bottom: 60px;
}

.AboutContentB-setbtn-read {
  padding: 10px 30px;
  border-radius: 5px;
  background: #01adef;
  border-color: #01adef;
}

.AboutContentB-confont-headsider {
  font-size: 25px;
  color: #01adef;
}

.AboutContentB-build-hr {
  height: 2px;
  width: 127px;
  background: #01adef;
  margin-bottom: 25px;
  margin-top: 10px;
}

.AboutContentB-confont-subheadsider {
  color: #01adef;
  font-size: 18px;
}

.AboutContentB-confont-sidert,
.AboutContentB-confont-siderb {
  font-size: 14px;
}

.AboutContentB-confont-siderb {
  color: #9a9a9a;
}

.AboutContentB-conpaddl-contact {
  padding-left: 60px;
}

.AboutContentB-confont-contact {
  color: #a8a9ac;
  font-size: 12px;
}

.AboutContentB-setmarb-infocarpricea {
  padding-top: 32px;
  margin-top: -34px;
}

.AboutContentB-setfloat-infocarprice {
  float: left;
  margin-right: 20px;
}

.AboutContentB-conwlogo-phone {
  width: 49px;
}

.AboutContentB-conwlogo-phonenum {
  width: 80px;
  margin-top: -18px;
}

.AboutContentB-setpad-sidel {
  padding-left: 150px;
}

.AboutContentB-setpad-sider {
  padding-left: 0;
}

.AboutContentB-addmart {
  margin-top: 10px;
}

.AboutContentB-marb-infob {
  margin-top: 50px;
  margin-bottom: 60px;
}

.AboutContentA-conw-fonthead {
  display: block;
}

.AboutContentA-confont-spanhead p {
  margin-bottom: 10px;
}

@media (max-width: 1200px) {
  .AboutContentB-confont-sidert,
  .AboutContentB-confont-siderb {
    font-size: 12px;
  }

  .AboutContentB-confont-headsider {
    font-size: 22px;
  }

  .AboutContentB-build-hr {
    width: 115px;
  }

  .AboutContentB-confont-subheadsider {
    font-size: 16px;
  }

  .AboutContentB-setpadsider-default {
    padding: 32px 0 0 0px;
  }

  .AboutContentB-repad-sider {
    /*padding: 0;*/
  }

  .AboutContentB-setpad-sidel {
    padding-left: 100px;
  }
}

@media (max-width: 992px) {
  .AboutContentB-setmarb-infocarpricea {
    margin-top: -24px;
  }
  .AboutContentB-setpadtb-imgdefault {
    padding: 0;
  }

  .AboutContentB-setpadsider-default {
    margin: 0 60px;
  }

  .AboutContentB-setpad-sidel {
    padding: 0;
    margin: 0 45px;
  }

  .AboutContentB-setpad-sider {
    margin: 0px 25px;
  }

  .row .col-md-6.AboutContentB-addmart-mo {
    margin-top: 45px;
  }

  .AboutContentB-conpaddl-contact {
    padding-left: 81px;
  }

  .AboutContentB-confont-contact {
    font-size: 14px;
  }

  .AboutContentB-confont-headsider {
    font-size: 25px;
  }

  .AboutContentB-confont-sidert,
  .AboutContentB-confont-siderb {
    font-size: 14px;
  }

  .AboutContentB-confont-subheadsider {
    font-size: 18px;
  }

  .AboutContentB-build-hr {
    width: 127px;
  }
}

@media (max-width: 768px) {
  .AboutContentB-repadrl-mo {
    padding: 0;
  }

  .AboutContentB-conpaddl-contactb {
    padding-left: 100px;
  }

  .AboutContentB-splittxt-mo {
    display: block;
  }

  .AboutContentB-conwlogo-phonenum {
    width: 90px;
    margin-top: 5px;
  }

  .AboutContentB-conwlogo-phone {
    width: 55px;
  }

  .AboutContentB-conpo-btn {
    text-align: center;
  }

  .AboutContentA-contain-default {
    margin: 130px 15px 0;
  }
}

@media (max-width: 570px) {
  .AboutContentA-conw-fonthead {
    display: contents;
  }

  .AboutContentB-setpad-sidel,
  .AboutContentB-setpad-sider {
    margin: 0 10px;
  }

  .AboutContentB-conwlogo-phonenum {
    margin-left: -21px;
  }

  .AboutContentB-conpaddl-contactb {
    padding-left: 76px;
  }

  .AboutContentB-conpaddl-contact {
    padding-left: 77px;
  }

  .AboutContentB-confont-contact {
    font-size: 13px;
  }

  .AboutContentB-conwlogo-phonenum {
    width: 75px;
  }

  .AboutContentB-conwlogo-phone {
    width: 45px;
  }

  .AboutContentB-setpadsider-default {
    margin: 0 20px;
    padding: 0;
  }

  .AboutContentB-confont-subheadsider {
    font-size: 16px;
  }

  .AboutContentB-confont-sidert,
  .AboutContentB-confont-siderb {
    font-size: 12px;
  }
}
